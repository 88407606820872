/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Label,
  Card,
  CardHeader,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  ButtonGroup,
  InputGroup,
  Button,
  CardBody,
  FormGroup,
  Input,
  // ButtonGroup,
} from "reactstrap";
import StandardHeader from "components/Headers/StandardHeader.js";
import config from "services/config";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import ListSlipTax from "./list/ListSlipTax";
import ListSlipsummary from "./list/ListSlipSummary";
import ListSlipfile from "./list/ListSlipFile";
import AsyncSelect from "react-select/async";
import { styleInputSelect } from "services/styles";
var moment = require("moment");

const nD = new Date();
const bac = nD.getFullYear() +"-"+("0" + (nD.getMonth() + 1)).slice(-2) +"-"+ ("0" + nD.getDate()).slice(-2);
const timel = 
  nD.getFullYear() +
  ("0" + (nD.getMonth() + 1)).slice(-2) +
  ("0" + nD.getDate()).slice(-2);
const dtime =
  nD.getFullYear() +
  "-" +
  ("0" + (nD.getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + nD.getDate()).slice(-2) +
  " " +
  ("0" + nD.getHours()).slice(-2) +
  ":" +
  ("0" + nD.getMinutes()).slice(-2) +
  ":" +
  nD.getSeconds();

class SettingBlastInvoice extends PureComponent {
  state = {
    listVendor: [],
    showFiles: [],
    listAllSlipTax: [],
    listSlip: [],
    listSlipSummary: [],
    // listPeriod: [],
    isFilter: false,
    isUpload: false,
    isLoading: false,
    isFilterLoading: false,
    isResetLoading: false,
    alert: null,
    showModalUpload: false,
    showModal: false,
    showModalDetail: false,
    file: "",
    headerDetail: "",
    show_custome: false,
    min_date: "",
    max_date: "",
    ct: "",
    show_upload: false,
    data: {
      company_id: "",
      slip: [],
      batch:bac,
      summary: "",
      params:0,
    },
    detail: {
      company_id: "",
      vendor: "",
      min: "",
      max: "",
    },
    delete: {
      id: "",
    },
    actionFilter: {
      company_id: "",
      // from_date: "",
      // to_date: moment().format("YYYY-MM-DD"),
    },
    actionListCompanies: {
      company_name: "",
    },
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.getListSlipTax();;
    this.setState({
      listSettings: [
        {
          value: "1",
          label: "All",
        },
        {
          value: "2",
          label: "Slip tax",
        },
        {
          value: "3",
          label: "Type PPH",
        },
      ],

      // listPeriod: [
      //   {
      //     value: "1",
      //     label: "All",
      //   },
      //   {
      //     value: "2",
      //     label: "Year",
      //   },
      //   {
      //     value: "3",
      //     label: "Month",
      //   },
      //   {
      //     value: "4",
      //     label: "Set",
      //   },
      // ],
    });
  };

  componentWillUnmount() {
    this.mounted = false;
  }
  fetchDataCompanies = (inputValue) => {
    let searchTerm = inputValue;
    if (!inputValue || inputValue === " ") {
      searchTerm = "";
    }
    this.setState({
      actionListCompanies: {
        ...this.state.actionListCompanies,
        company_name: searchTerm,
      },
    });

    const newRequest = axios.post(
      config.API_URL + "/companies/companies_public",
      { id: this.state.actionListCompanies },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      }
    );

    if (newRequest) {
      return newRequest.then((response) => {
        console.log(response.data.data)
        this.setState({
          company_id: response.data.data[0].company_id,
          company_name: response.data.data[0].company_name,
        });
        const compare = response.data.data.filter((i) =>
          i.company_name.toLowerCase().includes(inputValue.toLowerCase())
        );
        return compare.map((data) => ({
          label: data.company_name,
          value: data.company_id,
        }));
      });
    }
  };
  onSearchChangeCompanies = (selectedOptionCompanies) => {
    console.log(selectedOptionCompanies)
    if (selectedOptionCompanies) {
      this.setState(
        {
          selectedOptionCompanies: selectedOptionCompanies,
          showFiles: [],
          data: {
            ...this.state.data,
            company_id: selectedOptionCompanies.value,
          },
        },
        () => this.getListSlipTax()
      );
    } else {
      this.setState(
        {
          selectedOptionCompanies: null,
          showFiles: [],
          data: {
            ...this.state.data,
            company_id: "",
            slip: [],
            summary: "",
          },
        },
        () => this.getListSlipTax()
      );
    }
  };

  getListSlipTax = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(config.API_URL + "/tax/list_tax_slip", this.state.data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      })
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listAllSlipTax: res.data.data,
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  handleCancel = () => {
    this.setState(
      {
        isUpload: false,
        showModalUpload: false,
        showModal: false,
        selectedOptionCompanies: null,
        company_id: "",
        company_name: "",
        showFiles: [],
        data: {
          company_id: "",
          slip: [],
          summary: "",
        },
        detail: {
          ...this.state.detail,
          company_id: "",
          vendor: "",
        },
        alert:null
      },
      () => this.getListSlipTax()
    );
  };

  handleDelete = (event) => {
    this.setState(
      {
        alert: (
          <SweetAlert
            danger
            style={{ display: "block" }}
            title="Are you sure ?"
            onConfirm={() => this.handleActionDelete(event.id)}
            onCancel={() => this.hideAlert()}
            showCancel
            confirmBtnBsStyle="danger"
            confirmBtnText="Yes, delete!"
            cancelBtnBsStyle="link"
            cancelBtnText="Cancel"
            btnSize=""
          >
            <b>{`${event.files}`}</b> will be deleted, You won't be
            able to revert this!
          </SweetAlert>
        ),
      }
      // ,
      // () => {
      //   this.setState({
      //     dataDelivery: {
      //       ...this.state.dataDelivery,
      //       idInvoice: idInvoice,
      //     },
      //   },());
      // }
    );
  };
  handleAdd = () => {
    this.setState({
      showModalUpload: !this.state.showModalUpload,
    });
  };
  handleUpload = () => {
    var paramsValues
    if (Object.keys(this.state.data.slip).length >0 && this.state.data.summary) {
      paramsValues = 3
    } else if (Object.keys(this.state.data.slip).length >0){
      paramsValues = 2
    } else if  (this.state.data.summary){
      paramsValues = 1
    } else {
      paramsValues = 0
    }
    this.setState(
      {
        isLoading: true,
        data: {
          ...this.state.data,
          params: paramsValues
        },
      },
      () => {
        let formData = new FormData();
        formData.append("date", timel);
        formData.append("date_time", dtime);
        formData.append("company_id", this.state.data.company_id);
        formData.append("params", this.state.data.params);
        formData.append("summary", this.state.data.summary);
        
        var fa = Array.from(this.state.data.slip);
        for (let i = 0; i < fa.length; i++) {
          formData.append("file" + i, fa[i]);
        }
        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + ", " + pair[1]);
        // }

        //   for (let i = 0; i < fileUploads.length; i++) {
        //     formData.append(`images[${i}]`, this.state.data.fileUloads[i])
        // }
        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + ", " + pair[1]);
        // }

        // console.log(
        //   Object.keys(this.state.data.summary).length +
        //     " | " +
        //     Object.keys(this.state.data.slip).length
        // );

          // for (var pair of formData.entries()) {
          //   console.log(pair[0] + ", " + pair[1]);
          // }
        axios.post(config.API_URL + "/tax/upload_slip", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: config.TOKEN,
            },
          }).then(
            (res) => {
              if (this.mounted) {
                this.setState({ isLoading: false }, () => {
                  this.getListSlipTax();
                  this.successAlert(`Successfully, withholding tax slip has been send to ${this.state.company_name}`);
                });
              }
            },
            (err) => {
              if (this.mounted) {
                if (err.response !== undefined) {
                  this.failedAlert(err.response.data.data);
                  this.setState({ isLoading: false });
                }
              }
            }
          );

      } 
    );
  };
  handleActionDelete = (e) => {
    this.setState(
      {
        alert: null,
      },
      () =>
        axios
          .post(
            config.API_URL + "/tax/delete_slip",
            { id: e, request: config.USER_ID },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: config.TOKEN,
              },
            }
          )
          .then(
            (res) => {
              if (this.mounted) {
                this.setState(
                  { alert: true },
                  () => this.getListSlip(0),
                  this.getListSummary(1),
                  this.getListSlipTax()
                );
                this.successAlertDelete(res.data.data);
              }
            },
            (err) => {
              if (this.mounted) {
                if (err.response !== undefined) {
                  this.failedAlert(err.response.data.data);
                  this.setState({ alert: false });
                }
              }
            }
          )
    );
  };
  successAlertDelete = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
          timeout={5000}
        >
          {data} file has been successfully deleted.
        </SweetAlert>
      ),
    });
  };
  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.handleCancel()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
          timeout={10000}
        >
          {data.message}
          <br />
          {data}
        </SweetAlert>
      ),
    });
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  getListSlip = (a) => {
    this.setState(
      {
        detail: { ...this.state.detail, list: a },
      },
      () =>
        axios
          .post(config.API_URL + "/tax/list_slip", this.state.detail, {
            headers: {
              "Content-Type": "application/json",
              Authorization: config.TOKEN,
            },
          })
          .then(
            (res) => {
              if (this.mounted) {
                this.setState(
                  {
                    listSlip: res.data.data,
                    detail: {
                      ...this.state.detail,
                      min: res.data.min_date,
                      max: res.data.max_date,
                    },
                  },
                  () => {
                    this.getListSummary(1);
                  }
                  // () => {
                  //   if (this.state.isFilterLoading) {
                  //     this.setState({
                  //       isFilterLoading: false,
                  //       isFilter: true,
                  //     });
                  //   }
                  //   if (this.state.isResetLoading) {
                  //     this.setState({
                  //       isResetLoading: false,
                  //       isFilter: true,
                  //     });
                  //   }
                  // }
                );
              }
            },
            (err) => {
              console.log("error: ", err);
            }
          )
    );
  };
  getListSummary = (b) => {
    this.setState(
      {
        detail: { ...this.state.detail, list: b },
      },
      () =>
        axios
          .post(config.API_URL + "/tax/list_slip", this.state.detail, {
            headers: {
              "Content-Type": "application/json",
              Authorization: config.TOKEN,
            },
          })
          .then(
            (res) => {
              if (this.mounted) {
                this.setState(
                  {
                    listSlipSummary: res.data.data,
                    detail: {
                      ...this.state.detail,
                      min: res.data.min_date,
                      max: res.data.max_date,
                    },
                  }
                  // () => {
                  //   if (this.state.isFilterLoading) {
                  //     this.setState({
                  //       isFilterLoading: false,
                  //       isFilter: true,
                  //     });
                  //   }
                  //   if (this.state.isResetLoading) {
                  //     this.setState({
                  //       isResetLoading: false,
                  //       isFilter: true,
                  //     });
                  //   }
                  // }
                );
              }
            },
            (err) => {
              console.log("error: ", err);
            }
          )
    );
  };
  getListActivity = () => {
    axios
      .post(
        config.API_URL + "/tax/list_vendor_invoice_reguler_activity",
        this.state.activity,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listInvoiceStatusActivity: res.data.data,
              showModal: !this.state.showModal,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  handleReset = () => {
    this.setState(
      {
        show_custome: false,
        actionFilter: {
          ...this.state.actionFilter,
          company: "",
        },
      },
      () => {
        if (this.state.isResetLoading === false) {
          this.setState(
            {
              isResetLoading: true,
            },
            () => {
              this.getListSlipTax();
            }
          );
        }
      }
    );
  };
  handleResetSlip = () => {
    this.setState(
      {
        data: {
          company_id: "",
          slip: null,
        },
      },
      () => {
        this.getListSlipTax();
      }
    );
  };
  handleFilter = () => {
    this.getListInvoiceTax();
  };
  handleRowUpload = (e) => {
    this.setState({
      isUpload: true,
      company_name: e.company_name,
      data: {
        ...this.state.data,
        company_id: e.company_id,
      },
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
  handleChangeString = (event) => {
    if (event.target.name === "company_id") {
      this.setState({
        data: {
          ...this.state.data,
          [event.target.name]: event.target.value,
        },
      });
    } else {
      this.setState(
        {
          actionFilter: {
            ...this.state.actionFilter,
            [event.target.name]: event.target.value,
          },
        },
        () => this.getListSlipTax()
      );
    }
  };

  handleChangeFile = (event) => {
    const chosenFiles = Array.prototype.slice.call(event.target.files);
    this.setState({
      showFiles: [...chosenFiles],
      data: {
        ...this.state.data,
        slip: [...chosenFiles],
      },
    });
  };
  handleChangeSummary = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.files[0],
      },
    });
  };
  handleChangeString = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.files[0],
      },
    });
  };
  handleStatusDetails = (e) => {
    var id = e.id;
    this.setState(
      {
        activity: {
          select_id: id,
        },
      },
      () => {
        this.getListActivity();
      }
    );
  };
  hideModal = () => {
    this.setState({
      showModal: false,
      action: {
        select_id: "",
      },
    });
  };
  handleDetail = (e) => {
    var id = e.company_id;
    var vd = e.company_name;
    this.setState(
      {
        showModal: true,
        detail: {
          ...this.state.detail,
          company_id: id,
          vendor: vd,
        },
      },
      () => {
        this.getListSlip(0);
      }
    );
  };
  handleDetails = (e) => {
    this.setState({
      headerDetail: e.files,
      file: e.files,
      showModalDetail: true,
    });
  };
  hideModalDetail = () => {
    this.setState({
      ...this.state,
      showModalDetail: false,
      file: "",
    });
  };
  handleDownload=(e)=>{
    return (
      window.open(
        config.BUCKET_URL + e.files,
        "_blank"
        )
        )
  }
  render() {
    return (
      <>
        <StandardHeader name="Slip" parentName="Setting" link="setting-tax" />
        <Container className="mt--6" fluid>
          {/* {new upload} */}
          <Modal
            centered
            size="lg"
            backdrop="static"
            isOpen={this.state.showModalUpload}
            toggle={this.handleCancel}
          >
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0" toggle={this.hideModal}>
                <Row className="align-items-center">
                  <Col xs="6">
                    <h3 className="mb-0">Withholding tax slip</h3>
                  </Col>
                  <Col xs="6" className="text-right">
                    <button
                      type="button"
                      class="close"
                      aria-label="Close"
                      onClick={() => this.handleCancel()}
                    >
                      <span aria-hidden="true" style={{ fontSize: "1 rem" }}>
                        &times;
                      </span>
                    </button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  {this.state.data.company_id ? (
                    <>
                      {/* <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Batch
                          </label>
                          <div className="custom-file">
                            <input
                              className="custom-file-input"
                              id="customFileLang"
                              lang="en"
                              type="date"
                              name="batch"
                              onChange={this.handleChangeString}
                              required
                            />
                          </div>
                        </FormGroup>
                      </Col> */}
                      <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Summary Files {"(excel)"}
                          </label>
                          <div className="custom-file">
                            <input
                              className="custom-file-input"
                              id="customFileLang"
                              lang="en"
                              type="file"
                              name="summary"
                              accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/comma-separated-values, text/csv, application/csv"
                              onChange={this.handleChangeSummary}
                              required
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="customFileLang"
                            >
                              Select file
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Files {"(pdf)"}
                          </label>
                          <div className="custom-file">
                            <input
                              className="custom-file-input"
                              id="customFileLang"
                              lang="en"
                              type="file"
                              name="slip"
                              multiple
                              // value={this.state.data.slip}
                              accept="application/pdf"
                              onChange={this.handleChangeFile}
                              required
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="customFileLang"
                            >
                              Select file
                            </label>
                          </div>
                          <div className="p-2 m-2">
                            <table>
                              {this.state.showFiles.map((file, i) => (
                                <tr key={i}>
                                  <td style={{ fontSize: "0.600rem" }}>
                                    {i + 1}.
                                  </td>
                                  <td style={{ fontSize: "0.600rem" }}>
                                    {file.name}
                                  </td>
                                </tr>
                              ))}
                            </table>
                          </div>
                        </FormGroup>
                      </Col>
                    </>
                  ) : null}
                  <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                    <FormGroup style={{ position: "relative" }}>
                      <label className="form-control-label">Vendor</label>
                      <AsyncSelect
                        noOptionsMessage={() => "Not Found"}
                        cacheOptions
                        defaultOptions
                        isClearable
                        styles={styleInputSelect}
                        value={this.state.selectedOptionCompanies}
                        loadOptions={this.fetchDataCompanies}
                        onChange={(e) => {
                          this.onSearchChangeCompanies(e);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="justify-content-md-center">
                  {this.state.data.company_id &&(this.state.data.summary ||  Object.keys(this.state.data.slip).length > 0 )? (
                    <>
                      <Col
                        style={{ marginTop: 20 }}
                        className="col-6 col-md-6 col-sm-6"
                        xs="12"
                      >
                        <Button
                          block
                          color="primary"
                          className="btn-icon"
                          type="button"
                          disabled={this.state.isLoading ? true : false}
                          onClick={() => this.handleUpload()}
                        >
                          {this.state.isLoading ? (
                            <>
                              <span className="btn-inner--text">
                                <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                Upload
                              </span>
                            </>
                          ) : (
                            <span className="btn-inner--text">Upload</span>
                          )}
                        </Button>
                      </Col>
                    </>
                  ) : null}
                </Row>
              </CardBody>
            </Card>
          </Modal>
          {/* upload from list*/}
          <Modal
            centered
            size="lg"
            backdrop="static"
            isOpen={this.state.isUpload}
            toggle={this.handleCancel}
          >
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0" toggle={this.hideModal}>
                <Row className="align-items-center">
                  <Col xs="6">
                    <h3 className="mb-0">{this.state.company_name}</h3>
                    <small>Withholding tax slip</small>
                  </Col>
                  <Col xs="6" className="text-right">
                    <button
                      type="button"
                      class="close"
                      aria-label="Close"
                      onClick={() => this.handleCancel()}
                    >
                      <span aria-hidden="true" style={{ fontSize: "1 rem" }}>
                        &times;
                      </span>
                    </button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  {this.state.data.company_id ? (
                    <>
                      <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Summary Files {"(excel)"}
                          </label>
                          <div className="custom-file">
                            <input
                              className="custom-file-input"
                              id="customFileLang"
                              lang="en"
                              type="file"
                              name="summary"
                              accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/comma-separated-values, text/csv, application/csv"
                              onChange={this.handleChangeSummary}
                              required
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="customFileLang"
                            >
                              Select file
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Files {"(pdf)"}
                          </label>
                          <div className="custom-file">
                            <input
                              className="custom-file-input"
                              id="customFileLang"
                              lang="en"
                              type="file"
                              name="slip"
                              multiple
                              // value={this.state.data.slip}
                              accept="application/pdf"
                              onChange={this.handleChangeFile}
                              required
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="customFileLang"
                            >
                              Select file
                            </label>
                          </div>
                          <div className="p-2 m-2">
                            <table>
                              {this.state.showFiles.map((file, i) => (
                                <tr key={i}>
                                  <td style={{ fontSize: "0.600rem" }}>
                                    {i + 1}.
                                  </td>
                                  <td style={{ fontSize: "0.600rem" }}>
                                    {file.name}
                                  </td>
                                </tr>
                              ))}
                            </table>
                          </div>
                        </FormGroup>
                      </Col>
                    </>
                  ) : null}
                </Row>
                <Row className="justify-content-md-center">
                {this.state.data.company_id &&(this.state.data.summary ||  Object.keys(this.state.data.slip).length > 0 )? (
                    <>
                      <Col
                        style={{ marginTop: 20 }}
                        className="col-6 col-md-6 col-sm-6"
                        xs="12"
                      >
                        <Button
                          block
                          color="primary"
                          className="btn-icon"
                          type="button"
                          disabled={this.state.isLoading ? true : false}
                          onClick={() => this.handleUpload()}
                        >
                          {this.state.isLoading ? (
                            <>
                              <span className="btn-inner--text">
                                <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                Upload
                              </span>
                            </>
                          ) : (
                            <span className="btn-inner--text">Upload</span>
                          )}
                        </Button>
                      </Col>
                    </>
                  ) : null}
                  {/* {this.state.data.company_id && this.state.data.slip ? (
                    <>
                      <Col
                        style={{ marginTop: 20 }}
                        className="col-6 col-md-6 col-sm-6"
                        xs="12"
                      >
                        <Button
                          block
                          color="link"
                          className="btn-icon"
                          type="button"
                          disabled={this.state.isFilterLoading ? true : false}
                          onClick={() => this.handleUpload()}
                        >
                          {this.state.isFilterLoading ? (
                            <>
                              <span className="btn-inner--text">
                                <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                Upload
                              </span>
                            </>
                          ) : (
                            <span className="btn-inner--text">Upload</span>
                          )}
                        </Button>
                      </Col>
                    </>
                  ) : (
                    ""
                  )} */}
                </Row>
              </CardBody>
            </Card>
          </Modal>
          {/* slip  */}
          {/* <Modal
            centered
            size="lg"
            isOpen={this.state.showModal}
            toggle={this.handleDetail}
          >
            <ModalHeader toggle={this.hideModal}>
              {this.state.detail.vendor}
            </ModalHeader>
            <ModalBody>
              <Table
                responsive
                size="sm"
                striped
                hover
                scrollable="true"
                bordered
                >
                <tr>
                  <th className="text-center">Input date</th>
                  <th className="text-center">list entries</th>
                  <th>Name file</th>
                  <th className="text-right">Action</th>
                </tr>
                {this.state.listSlip.map((listAct, index) => {
                  if (listAct.list==1){
                    return (
                      <>
                        <tr key={index}>
                          <td className="text-center">
                            {moment(listAct.created_at).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                          <td className="text-center">{listAct.que}</td>
                          <td>{listAct.files.slice(5, -4)}</td>
                          <td className="text-right">
                            <Button
                              id="pr"
                              size="sm"
                              type="button"
                              style={{
                                cursor: "pointer",
                                objectFit: "cover",
                              }}
                              onClick={() =>
                                window.open(
                                  config.BUCKET_URL + listAct.files,
                                  "_blank"
                                )
                              }
                            >
                              <i className="fa fa-download text-primary" />
                            </Button>
                            &emsp;
                            <Button
                              id="pr"
                              size="sm"
                              type="button"
                              style={{
                                cursor: "pointer",
                                objectFit: "cover",
                              }}
                              onClick={() => this.handleDelete(listAct)}
                            >
                              <i className="fa fa-trash text-danger" />
                            </Button>
                          </td>
                        </tr>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <tr key={index}>
                          <td className="text-center">
                            {moment(listAct.created_at).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                          <td className="text-center">{listAct.que}</td>
                          <td>{listAct.files.slice(5, -4)}</td>
                          <td className="text-right">
                            <Button
                              id="dl"
                              size="sm"
                              type="button"
                              style={{ cursor: "pointer" }}
                              onClick={() => this.handleDetails(listAct)}
                            >
                              <i className="fas fa-file-pdf " />
                            </Button>
                            &emsp;
                            <Button
                              id="pr"
                              size="sm"
                              type="button"
                              style={{
                                cursor: "pointer",
                                objectFit: "cover",
                              }}
                              onClick={() =>
                                window.open(
                                  config.BUCKET_URL + listAct.files,
                                  "_blank"
                                )
                              }
                            >
                              <i className="fa fa-download text-primary" />
                            </Button>
                            &emsp;
                            <Button
                              id="pr"
                              size="sm"
                              type="button"
                              style={{
                                cursor: "pointer",
                                objectFit: "cover",
                              }}
                              onClick={() => this.handleDelete(listAct)}
                            >
                              <i className="fa fa-trash text-danger" />
                            </Button>
                          </td>
                        </tr>
                      </>
                    );

                  }
                })}
              </Table>
            </ModalBody>
          </Modal> */}

          {/* view object */}
          <Modal
            centered
            fullscreen=""
            backdrop="static"
            size="lg"
            isOpen={this.state.showModalDetail}
            toggle={this.handleDetails}
          >
            <ModalHeader toggle={this.hideModalDetail}>
              {this.state.headerDetail ? this.state.headerDetail.slice(5, -4): null}
            </ModalHeader>
            <ModalBody>
              <object
                width="100%"
                height="790"
                data={config.BUCKET_URL + this.state.file}
                type="application/pdf"
              >
                {" "}
              </object>
            </ModalBody>
          </Modal>
          <Row className="justify-content-center">
            <div className="col-md-6">
              <div className="card-wrapper">
                <ListSlipTax
                  info={
                    <>
                      <Row>
                        <Col sm={9} xs={12}>
                          <AsyncSelect
                            noOptionsMessage={() => "Not Found"}
                            cacheOptions
                            defaultOptions
                            isClearable
                            styles={styleInputSelect}
                            value={this.state.selectedOptionCompanies}
                            loadOptions={this.fetchDataCompanies}
                            onChange={(e) => {
                              this.onSearchChangeCompanies(e);
                            }}
                          />
                        </Col>
                        <Col sm={3} xs={12}>
                          <Button
                            block
                            color="success"
                            type="button"
                            className="btn-icon"
                            size="lg"
                            onClick={() => this.handleAdd()}
                          >
                            <span className="btn-inner--text">
                              <i className="ni ni-fat-add" />
                              Upload
                            </span>
                          </Button>
                        </Col>
                      </Row>
                    </>
                  }
                  list={this.state.listAllSlipTax}
                  upload={this.handleRowUpload}
                  detail={this.handleDetail}
                />
              </div>
            </div>
            {this.state.showModal ? (
              <>
                <div className="col-md-6">
                  <Row>
                    <Col md={12}>
                      <div className="card-wrapper">
                        <ListSlipfile
                          info={
                            <>
                              <Row>
                                <Col sm={9} xs={12}>
                                  <h3>
                                    List Files &emsp;
                                    {`${this.state.detail.company_id} - ${this.state.detail.vendor} `}
                                  </h3>
                                </Col>
                                <Col sm={3} xs={12} className="text-right">
                                  <button
                                    type="button"
                                    class="close"
                                    aria-label="Close"
                                    onClick={() =>
                                      this.setState({
                                        ...this.state,
                                        showModal: false,
                                        listSlip: [],
                                        listSlipSummary: [],
                                        detail: {
                                          company_id: "",
                                          vendor: "",
                                          min: "",
                                          max: "",
                                        },
                                      })
                                    }
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </Col>
                              </Row>
                            </>
                          }
                          list={this.state.listSlip}
                          view={this.handleDetails}
                          // download={this.handleDownload}
                          delete={this.handleDelete}
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="card-wrapper">
                        <ListSlipsummary
                          info={
                            <>
                              <h3>List Summary Files</h3>
                            </>
                          }
                          list={this.state.listSlipSummary}
                          // view={this.handleDetails}
                          download={this.handleDownload}
                          delete={this.handleDelete}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            ) : null}
          </Row>
          {this.state.alert}
        </Container>
      </>
    );
  }
}

export default SettingBlastInvoice;
