import React, { PureComponent } from "react";
import {
  Row,
  Col,
  InputGroup,
  Input,
  Container,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import config from "services/config";
import TaxSlipTable from "./TaxSlipVendor";
import axios from "axios";
class TaxSlip extends PureComponent {
  state = {
    thisShow: "",
    view_profile: false,
    listTaxSlip:[],
    filter:{
      search:""
    },
    data: {
      company_name: "",
      company_phone: "",
      company_address: "",
      company_siuk: "",
      company_siuk_file: "",
      company_update: "",
    },
  };

  componentDidMount() {
    this.mounted = true;
    this.onChangePreview = this.onChangePreview.bind(this);
    this.getListCompany();
    this.getTaxSlip();
  
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  getTaxSlip = () => {
    axios
      .post(
        config.API_URL + "/vendor/tax_slip_vendor",
        { company_id: config.COMPANY_ID,search:this.state.filter.search },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listTaxSlip: res.data.data,
            },
            // () => {
            //   if (this.state.isFilterLoading) {
            //     this.setState({
            //       isFilterLoading: false,
            //       isFilter: true,
            //     });
            //   }
            //   if (this.state.isResetLoading) {
            //     this.setState({
            //       isResetLoading: false,
            //       isFilter: true,
            //     });
            //   }
            // }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getListCompany = () => {
    axios
      .post(
        config.API_URL + "/vendor/list_vendor_settings",
        { company_id: config.COMPANY_ID },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              data: {
                company_name: res.data.data[0].company_name,
                company_phone: res.data.data[0].company_phone,
                company_address: res.data.data[0].company_address,
                company_siuk: res.data.data[0].siuk,
                company_siuk_file: res.data.data[0].siuk_file,
                company_update: res.data.data[0].updated_at,
              },
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

 
  onChangePreview = (a) => {
    const fileUploaded = a;
    // console.log(fileUploaded);
    this.setState({ thisShow: fileUploaded });
  };
  handleClose = () => {
    this.setState({ thisShow: "" });
  };

  handleCancel = () => {
    this.setState({
      view_profile: !this.state.view_profile,
    });
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
  handleEdit() {
    this.props.history.push("/vendor/settings-edit");
  }
  onChangePreview = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          title=""
          style={{ width: "900px"}}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="secondary"
          confirmBtnText="Close"
          btnSize=""
        >
          <object
          
            width="100%"
            height="700"
            data={config.BUCKET_URL + data}
            type="application/pdf"
          >
            {" "}
          </object>
        </SweetAlert>
      ),
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  handlePreview =(e) =>{
  var file = e.files
  this.onChangePreview(file)
}
handleDownload =(e) =>{
    var file = e.files
    console.log(e)
    window.open(
      config.BUCKET_URL + file,
      "_blank"
    )
  }
  handleChangeString = (event) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [event.target.name]: event.target.value,
        },
      },
      () => {
        this.getTaxSlip();
      }
    );
  };
  render() {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    // const nD = this.state.data.company_update.toLocaleString("en-US");

    // console.log(nD)
    var c =this.state.data.company_update
    var ct = c.slice(0, 19).replace('T', ' ')
    // console.log(c)
    return (
      <>
        <Container className="mt-6" fluid>
          <Row className="justify-content-center">

            <Col className="col-lg-8 col-md-8 col-sm-12" xs="12">
           
                <TaxSlipTable
                  list={this.state.listTaxSlip}
                  info={
                    <>
                    <Row>
                    <Col className="text-left">
                      <h3 className="mb-0">Tax slip</h3>
                    </Col>
                     <Col className="text-right" >
                     <InputGroup>
                            <Input
                              bsSize="sm"
                              type="search"
                              className="search"
                              id="search"
                              placeholder="Search file name"
                              result
                              onKeyPress={this.keyPressed}
                              name="search"
                              value={this.state.filter.search}
                              onChange={this.handleChangeString}
                            />
                              <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            style={{ fontSize: "0.9em", height: "31px",border:"1px solid #D6D9DE",borderTopLeftRadius:"0px",borderBottomLeftRadius:"0px", }}
                            onClick={() => this.setState({filter:{...this.state.filter,search:""}},()=> this.getTaxSlip())}
                          >
                            
                            <i className="fa fa-times-circle text-default" />
                          </button>
                          </InputGroup>
                    </Col>
                  </Row>
                    </>
                  }
                  view={this.handlePreview}
                  download={this.handleDownload}
                />
            </Col>
          </Row>
          {this.state.alert}
        </Container>
      </>
    );
  }
}

export default TaxSlip;
