/* eslint-disable eqeqeq */
import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  Button,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap";
import StandardHeader from "components/Headers/StandardHeader.js";
import config from "services/config";
import axios from "axios";
// import { validateInvoiceRegulerTax } from "services/validate";
import SweetAlert from "react-bootstrap-sweetalert";
import { currencyIDR } from "services/currency";
import { uuidv5 } from "services/uuidv5";
import { webdatetime } from "services/sysdate";
import moment from "moment";
var uuid = uuidv5(8);
// import { Redirect } from 'react-router-dom';
class TaxInvoiceDetail extends PureComponent {
  state = {
    listCurrencies: [],
    listTypePph: [],
    listTypeInvoice: [],
    setPPN: "",
    valPPN: "",
    match_pph: [],
    pros_pph: "",
    custome_pph: "",
    preview: "",
    checked: false,
    checkedPph: false,
    checkedReject: false,
    pphRate: "",
    data: {
      id: "",
      rv: 0,
      invoice_number: "",
      purchase_order: "",
      good_receipt: "",
      bast: "",
      tax_invoice: "",
      delivery_note: "",
      siuk: "",
      invoice_number_file: "",
      purchase_order_file: "",
      good_receipt_file: "",
      bast_file: "",
      tax_invoice_file: "",
      delivery_note_file: "",
      siuk_file: "",
      invoice_type: "",
    },
    submit: {
      user_id: config.USER_ID,
      datetime: webdatetime(),
      type_invoice: "",
      type_invoice_code: uuid,
      reject: 0,
      note: "",
      ppn: 0,
      ppn_rate: "",
      pph: 0,
      type_pph: "",
      custome_type_pph: "",
      tax: "",
      tax_rate: "",
      invoice_total: 0,
      curr: "54",
    },
    isLoading: false,
    alert: null,
    paramError: {},
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.getListInvoice();
    this.onChangePreview = this.onChangePreview.bind(this);
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  getListInvoice = async () => {
    try {
      const response = await axios.post(
        config.API_URL + "/tax/detail_invoice_tax",
        { key: this.props.match.params.id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      this.setState({
        data: {
          id: response.data.data[0].id,
          company_name: response.data.data[0].company_name,
          rv: response.data.data[0].rev,
          invoice_number: response.data.data[0].invoice,
          invoice_number_file: response.data.data[0].invoice_file,
          purchase_order: response.data.data[0].purchase_order,
          purchase_order_file: response.data.data[0].purchase_order_file,
          good_receipt: response.data.data[0].good_receipt,
          good_receipt_file: response.data.data[0].good_receipt_file,
          bast: response.data.data[0].bast,
          bast_file: response.data.data[0].bast_file,
          tax_invoice: response.data.data[0].tax_invoice,
          tax_invoice_file: response.data.data[0].tax_invoice_file,
          delivery_note: response.data.data[0].delivery_note,
          delivery_note_file: response.data.data[0].delivery_note_file,
          siuk: response.data.data[0].siuk,
          siuk_file: response.data.data[0].siuk_file,

          is_verified: response.data.data[0].is_verified,
          invoice_amount: response.data.data[0].invoice_amount,

          curr: response.data.data[0].curr,
          ppn: response.data.data[0].ppn,
          ppn_rate: response.data.data[0].ppn_rate,
          tax_ppn: response.data.data[0].tax_ppn,

          pph: response.data.data[0].pph,
          tax_pph_dpp: response.data.data[0].tax_pph_dpp,
          tax_rate: response.data.data[0].tax_rate,
          tax_pph: response.data.data[0].tax_pph,
          set_pph: response.data.data[0].set_pph,
          pph_id: response.data.data[0].pph_id,
          invoice_total: response.data.data[0].total_invoice,

          invoice_status: response.data.data[0].invoice_status,
          invoice_info: response.data.data[0].invoice_info,
          status_id: response.data.data[0].is_reject,
          note: response.data.data[0].note,
          updated_at: response.data.data[0].updated_at,
          created_on: response.data.data[0].created_on,
          invoice_type: response.data.data[0].invoice_type,
          tax_pic: response.data.data[0].username,
          id_pic: response.data.data[0].pic_id,
        },
      });
    } catch (error) {
      // this.props.history.push("/tax/active-invoice-tax");
      console.log(error)
    }
  };

  onChangePreview = (a) => {
    const fileUploaded = a;
    this.setState({ preview: fileUploaded });
  };
  handleCancel = (e) => {
    // console.log(e);
    if (e == 1) {
      this.props.history.push("/tax/all-invoice-tax/"+1);
    } else if (e == 2) {
      this.props.history.push("/tax/all-invoice-tax/"+2);
    } else {
      this.props.history.push("/tax/all-invoice-tax/"+3);
    }
  };
  handleClose = () => {
    this.setState({ preview: "" });
  };

  onChangePreview = (data) => {
    // console.log(data);
    this.setState({
      alert: (
        <SweetAlert
          title=""
          style={{ display: "block", width: "60%" }}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="link"
          confirmBtnText="Close"
          btnSize=""
        >
          <object
            width="100%"
            height="790"
            data={config.BUCKET_URL + data}
            type="application/pdf"
          >
            {" "}
          </object>
        </SweetAlert>
      ),
    });
  };

  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          // onConfirm={() => this.hideAlert()}
          onConfirm={() => this.handleCancel()}
          // onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
          timeout={1000}
        >
          Invoice&nbsp; <b>{`${data.data}`}</b>&nbsp;{`${data.message}`}
        </SweetAlert>
      ),
    });
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  handleChangePpn(e) {
    let isChecked = e.target.checked;
    // let addPPN = this.state.data.invoice_amount * this.state.setPPN
    // let inc_ppn = this.state.data.invoice_amount + addPPN
    // const padded = ("." + this.state.setPPN + "").padStart(4, "0");
    // let addPPN = this.state.data.invoice_amount * this.state.setPPN
    let pad = this.state.setPPN;
    const add_ppn = ("." + pad + "").padStart(4, "0");
    var addPPN = this.state.data.invoice_amount * add_ppn;
    if (isChecked === true) {
      var total;
      if (this.state.submit.tax_rate !== "") {
        total = this.state.submit.invoice_total + addPPN;
      } else {
        total = this.state.data.invoice_amount + addPPN;
      }
      this.setState({
        submit: {
          ...this.state.submit,
          ppn: 1,
          invoice_total: total,
        },
        valPPN: addPPN,
        checked: isChecked,
      });
    } else {
      this.setState({
        submit: {
          ...this.state.submit,
          ppn: 0,
          invoice_total: this.state.submit.invoice_total - addPPN,
        },
        valPPN: "",
        checked: isChecked,
      });
    }
  }
  handleChangePph = (event) => {
    // console.log(event.target.name + " | " + event.target.value);
    if (event.target.value === "custome_pph") {
      if (this.state.checked === true) {
        this.setState({
          submit: {
            ...this.state.submit,
            [event.target.name]: event.target.value,
            tax: "",
            tax_rate: "",
            invoice_total: this.state.data.invoice_amount + this.state.valPPN,
          },
        });
      } else {
        this.setState({
          pphRate: "",
          submit: {
            ...this.state.submit,
            [event.target.name]: event.target.value,
            tax: "",
            invoice_total: "",
          },
        });
      }
    } else if (event.target.value !== "") {
      var ratePph;
      var key = Number(event.target.value);
      this.state.listTypePph
        .filter((poItems) => poItems.pph_type_id === key)
        .map((items) => (ratePph = items.pph_rate));
      var invoice = parseInt(this.state.data.invoice_amount);
      var taxSub = Number(ratePph);
      var taxRate = (invoice * taxSub) / 100;
      var paramTotal;
      if (this.state.checked === true) {
        let addPPN = this.state.data.invoice_amount + this.state.valPPN;
        paramTotal = addPPN - taxRate;
      } else {
        paramTotal = this.state.data.invoice_amount - taxRate;
      }

      this.setState({
        ...this.state,
        pphRate: taxRate,
        submit: {
          ...this.state.submit,
          custome_type_pph: "",
          [event.target.name]: event.target.value,
          tax: taxSub,
          tax_rate: taxRate,
          invoice_total: paramTotal,
        },
      });
    } else {
      if (this.state.checked === true) {
        this.setState({
          ...this.state,
          pphRate: "",
          submit: {
            ...this.state.submit,
            type_pph: "",
            tax: "",
            invoice_total: this.state.data.invoice_amount + this.state.valPPN,
          },
        });
      } else {
        this.setState({
          ...this.state,
          pphRate: "",
          submit: {
            ...this.state.submit,
            type_pph: "",
            tax: "",
            invoice_total: this.state.data.invoice_amount,
          },
        });
      }
    }
  };
  handleChange(e) {
    let isCheckedPph = e.target.checked;
    if (isCheckedPph === true) {
      this.setState({
        submit: {
          ...this.state.submit,
          pph: 1,
        },
        checkedPph: isCheckedPph,
      });
    } else {
      // var invoice = parseInt( this.state.data.invoice_amount)
      var del_pph = this.state.data.invoice_amount + this.state.valPPN;
      this.setState({
        submit: {
          ...this.state.submit,
          pph: 0,
          type_pph: "",
          custome_type_pph: "",
          invoice_total: del_pph,
        },
        checkedPph: isCheckedPph,
      });
    }
  }
  render() {
    var status 
    if (this.state.data.status_id == 3) {
      status = <h2 className="mb--1"><i className="fa fa-check-circle" /> APPROVE</h2>
    } else if (this.state.data.status_id == 2){
      status = <h2 className="mb--1"><i className="fa fa-times-circle" /> REJECT</h2>
    } else {
      status = <h2 className="mb--1"><i className="fa fa-times-circle" /> WAITING</h2>
    }
    return (
      <>
        <StandardHeader
          name="Detail"
          parentName="Invoice"
          link="active-invoice-tax"
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col className="col-lg-12 col-md-12" sm="12" xs="12">
              <Card >
                <CardBody>
                  <button
                    type="button"
                    className="close mt--2 mr--2"
                    aria-label="Close"
                    onClick={() =>
                      this.handleCancel(this.props.match.params.fr)
                    }
                  >
                    <span aria-hidden="true" style={{ fontSize: "1 rem" }}>
                      &times;
                    </span>
                  </button>
                  <Row className="mx-4">
                    <Col xs="6" md="6">
                      <h2 className="mb--1">{this.state.data.company_name}</h2>
                      <span
                        style={{ fontSize: "0.75em" }}
                        className="text-muted"
                      >
                        {this.state.data.created_on ? (
                          <>
                            Created on{" "}
                            {" " +
                              moment(this.state.data.created_on).format(
                                "HH:mm DD-MM-YYYY"
                              )}
                          </>
                        ) : null}
                      </span>
                    </Col>
                    <Col md="6" xs="6" className="my-auto text-right">
                      {status}
                      {this.state.data.invoice_info === 5 || this.state.data.invoice_info === 2 ||  this.state.data.invoice_info === 7 ? null :(
                        <>
         <span
        style={{ fontSize: "0.75em" }}
        className="text-muted"
      >
        {this.state.data.id_pic != config.USER_ID ? this.state.data.tax_pic : "Verified"}
            {" "+
              moment(this.state.data.updated_at).format(
                "HH:mm DD-MM-YYYY"
              )}
      </span>
                        </>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          {this.state.data ?
          (
            <>
            <Col className="col-lg-6 col-md-6" sm="12" xs="12">
              <Card className="shadow">
                <CardBody>
                  {/* <FormGroup> */}
                  <Row className="mx-4 mt-3">
                    <Col md="6" className="my-auto text-muted">
                      Invoice number
                    </Col>
                    <Col md="6" className="my-auto">
                          {/* <Label className="form-control-label"> */}
                        <Button
                         className="btn btn-sm btn-secondary"
                         style={{ objectFit: "cover", cursor: "pointer" }}
                         onClick={() =>
                           this.onChangePreview(
                             this.state.data.invoice_number_file
                           )
                         }
                        >
                        {this.state.data.invoice_number}
                        {this.state.data.rv ? (
                          <h2>
                            <span className="text-primary pull-right">
                              {" R" + this.state.data.rv}
                            </span>
                            </h2>
                            ): null}
                        </Button> 
                      {/* </Label> */}
                    </Col>
                  </Row>
                  {/* </FormGroup> */}
                  <hr className="mx-4 my-2" />
                  {/* <FormGroup> */}
                  <Row className="mx-4">
                    <Col md="6" className="my-auto text-muted">
                      Tax Invoice
                      <small>
                        <i className="text-muted"> Faktur pajak</i>
                      </small>
                    </Col>
                    <Col md="6" className="my-auto">
                      <Label className="form-control-label">
                        {this.state.data.tax_invoice}
                      </Label>
                    </Col>
                  </Row>
                  {/* </FormGroup> */}
                  <hr className="mx-4 my-2" />
                  {/* <FormGroup> */}
                  <Row className="mx-4">
                    <Col md="6" className="my-auto text-muted">
                      Purchase order (PO)
                    </Col>
                    <Col md="6" className="my-auto">
                      <Label className="form-control-label">
                        {this.state.data.purchase_order}
                      </Label>
                    </Col>
                  </Row>
                  {/* </FormGroup> */}
                  <hr className="mx-4 my-2" />
                  {/* <FormGroup> */}
                  <Row className="mx-4">
                    <Col md="6" className="my-auto text-muted">
                      Good receipt (GR)
                    </Col>
                    <Col md="6" className="my-auto">
                      <Label className="form-control-label">
                        {this.state.data.good_receipt}
                      </Label>
                    </Col>
                  </Row>
                  {/* </FormGroup> */}
                  <hr className="mx-4 my-2" />
                  {/* <FormGroup> */}
                  <Row className="mx-4">
                    <Col md="6" className="my-auto text-muted">
                      Delivery note
                    </Col>
                    <Col md="6" className="my-auto">
                      <Label className="form-control-label">
                        {this.state.data.delivery_note}
                      </Label>
                    </Col>
                  </Row>
                  {/* </FormGroup> */}
                  <hr className="mx-4 my-2" />
                  {/* <FormGroup> */}
                  <Row className="mx-4">
                    <Col md="6" className="my-auto text-muted">
                      Minutes of hand over
                      <small>
                        <i className="text-muted"> BAST</i>
                      </small>
                    </Col>
                    <Col md="6" className="my-auto">
                      <Label className="form-control-label">
                        {this.state.data.bast}
                      </Label>
                    </Col>
                  </Row>
                  <hr className="mx-4 my-2" />
                  {/* </FormGroup> */}
                </CardBody>
              </Card>
              {this.state.data.siuk_file ? (
              <Card className="shadow">
                <CardBody>
                  {this.state.data.siuk_file ? (
                    <>
                      <Row className="mx-4">
                        <Col md="12" className="my-auto text-muted">
                          Construction business license
                          <i className="text-muted">
                            Surat izin usaha kontruksi (SIUK)
                          </i>
                        </Col>
                      </Row>
                      <FormGroup>
                        <Row className="mx-4">
                          <Col md="8" xs="8" className="my-auto">
                            &emsp;{this.state.data.siuk}
                          </Col>
                          <Col md="4" xs="4" className="my-auto text-right">
                            <Button
                              size="sm"
                              type="button"
                              id="dl"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                window.open(
                                  config.BUCKET_URL + this.state.data.siuk_file,
                                  "_blank"
                                )
                              }
                            >
                              <i className="fa fa-download"></i>
                            </Button>
                            <UncontrolledTooltip flip delay={0} target={"dl"}>
                              Donwload File
                            </UncontrolledTooltip>
                            <UncontrolledTooltip flip delay={0} target={"pr"}>
                              Preview File
                            </UncontrolledTooltip>{" "}
                            <Button
                              size="sm"
                              type="button"
                              id="pr"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.onChangePreview(this.state.data.siuk_file)
                              }
                            >
                              <i className="fa fa-file-pdf-o"></i>
                            </Button>
                          </Col>
                        </Row>
                      </FormGroup>
                      <hr className="mx-4 mt--1 mb-2" />
                    </>
                  ) : null}
                
                </CardBody>
              </Card>
              ): null}
            </Col>
            <Col className="col-lg-6 col-md-6" sm="12" xs="12">
              <Card className=" shadow">
                <CardBody>
                  <div className="mb-4 mt-1 text-center">
                    <h3>{this.state.data.invoice_type}</h3>
                  </div>
                  <hr className="mx-4 my-2 " />
                      {this.state.data.ppn == 0 &&
                      this.state.data.pph == 0 ? null : (
                        <>
                          <Row className="mx-4 my-3">
                            <Col style={{ fontSize: "1.10em" }}>
                              Invoice amount
                            </Col>
                            <Col className="text-right">
                              <Label
                                className="form-control-label"
                                style={{ fontSize: "1.10em" }}
                              >
                                {currencyIDR(
                                  this.state.data.invoice_amount
                                ).replace("IDR", "RP")}
                              </Label>
                            </Col>
                          </Row>
                          <hr className="mx-4 my-2 " />
                        </>
                      )}
                      {this.state.data.ppn == 1 ? (
                        <>
                          <Row className="mx-4 my-1">
                            <Col
                              md="6"
                              className={
                                this.state.data.ppn ? null : "text-muted"
                              }
                            >
                              <span
                                style={{
                                  fontSize: "0.82em",
                                  paddingLeft: "20px",
                                }}
                              >
                                PPN
                              </span>{" "}
                              <span style={{ fontSize: "0.82em" }}>
                              {this.state.data.tax_ppn + "%"}
                              </span>
                              {/* {this.state.data.ppn ? (
                                <Label
                                  style={{ fontSize: "0.82em" }}
                                  className="form-control-label"
                                >
                                  {" "}
                                  <i className="fa fa-check-circle" />{" "}
                                  <span className="text-secondary">
                                    {this.state.data.tax_ppn + "%"}
                                  </span>
                                </Label>
                              ) : (
                                <Label
                                  style={{ fontSize: "0.82em" }}
                                  className="form-control-label"
                                >
                                  <i className="fa fa-times-circle text-muted" />
                                </Label>
                              )} */}
                            </Col>
                            {this.state.data.ppn && this.state.data.tax_ppn ? (
                              <Col md="6" className="text-right">
                                <Label className="form-control-label">
                                  {currencyIDR(
                                    this.state.data.ppn_rate
                                  ).replace("IDR", "")}
                                </Label>
                              </Col>
                            ) : null}
                          </Row>
                      {this.state.data.pph == 1 ? (
                        <>
                         <Row className="mx-4 my-1">
                            <Col
                              md="6"
                              className={
                                this.state.data.pph ? null : "text-muted"
                              }
                            >
                              <span
                                style={{
                                  fontSize: "0.82em",
                                  paddingLeft: "20px",
                                }}
                              >
                                PPH DPP
                              </span>{" "}
                            </Col>
                            {this.state.data.pph && this.state.data.tax_pph ? (
                              <Col md="6" className="text-right">
                                <Label className="form-control-label">
                                  {currencyIDR(
                                    this.state.data.tax_pph_dpp
                                  ).replace("IDR", "")}
                                </Label>
                              </Col>
                            ) : null}
                          </Row>
                          <Row className="mx-4 my-1">
                            <Col
                              md="6"
                              className={
                                this.state.data.pph ? null : "text-muted"
                              }
                            >
                              <span
                                style={{
                                  fontSize: "0.82em",
                                  paddingLeft: "20px",
                                }}
                              >
                                PPH
                              </span>{" "}
                              <span style={{ fontSize: "0.82em" }}>
                                {this.state.data.tax_pph + "%"}
                                </span>
                              {/* {this.state.data.pph ? (
                                <Label
                                  style={{ fontSize: "0.82em" }}
                                  className="form-control-label"
                                >
                                  {" "}
                                  <i className="fa fa-check-circle " />{" "}
                                  {this.state.data.tax_pph + "%"}
                                </Label>
                              ) : (
                                <Label
                                  style={{ fontSize: "0.82em" }}
                                  className="form-control-label"
                                >
                                  <i className="fa fa-times-circle text-muted" />
                                </Label>
                              )} */}
                            </Col>
                            {this.state.data.pph && this.state.data.tax_pph ? (
                              <Col md="6" className="text-right">
                                <Label className="form-control-label">
                                  {currencyIDR(
                                    this.state.data.tax_rate
                                  ).replace("IDR", "- ")}
                                </Label>
                              </Col>
                            ) : null}
                          </Row>
                        </>
                      ) : null}
                      <hr className="mx-4 my-2 " />
                    </>
                  ) : null}

                  <FormGroup>
                    <Row className="mx-4 my-3">
                      <Col sm={6}>
                        <Label
                          className="form-control-label my-auto text-left"
                          style={{ fontSize: "1.24em" }}
                        >
                          Invoice total
                        </Label>
                      </Col>
                      <Col sm={6}>
                            <Label className="my-auto float-right">
                              <div
                                // className="mr-3"
                                style={{
                                  fontSize: "1.24em",
                                }}
                              >
                                    <strong>
                                      {currencyIDR(
                                        this.state.data.invoice_total
                                      ).replace("IDR", "RP")}
                                    </strong>
                                 
                              </div>
                            </Label>
                      </Col>
                    </Row>
                  </FormGroup>
                  <hr className="mx-4 my-3 " />
                  <FormGroup>
                    {/* <Row className="mx-4">
                      <Col className="text-left">
                        {this.state.data.status_id == 7 ? (
                          <span
                            className="btn btn-sm btn-secondary"
                            style={{
                              fontSize: "0.70em",
                              pointerEvents: "none",
                              borderRadius: "45px",
                            }}
                          >
                            <i className="fa fa-times-circle" /> REJECT
                          </span>
                        ) : (
                          <span
                            className="btn btn-sm btn-secondary"
                            style={{
                              fontSize: "0.70em",
                              pointerEvents: "none",
                              borderRadius: "45px",
                            }}
                          >
                            <i className="fa fa-check-circle" /> APPROVE
                          </span>
                        )}
                      </Col>
                      <Col className="text-right">
                        
                        <span
                          style={{ fontSize: "0.75em" }}
                          className="text-muted"
                        >
                          {this.state.data.updated_at ? (
                            <>
                              <span
                                className="fa-stack fa-lg"
                                style={{ fontSize: "0.8em" }}
                              >
                                <i className="fa fa-certificate fa-stack-2x "></i>
                                <i className="fa fa-check fa-stack-1x fa-inverse"></i>
                              </span>{" "}
                              Verified{" "}
                              {" " +
                                moment(this.state.data.updated_at).format(
                                  "HH:mm DD-MM-YYYY"
                                )}
                            </>
                          ) : null}
                        </span>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col>
                      {this.state.data.status_id === 2 ? (
                    <>
                      <FormGroup>
                        <Row className="mx-4 mt-4">
                          <Col sm={12}>
                            <Input
                              disabled
                              name="note"
                              value={this.state.data.note}
                              type="textarea"
                            />
                            <div className="invalid-feedback">
                              {this.state.paramError.note}
                            </div>
                          </Col>
                        </Row>
                      </FormGroup>
                    </>
                  ) : null}
                      </Col>
                    </Row>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
            </>
          ):null
        }
          </Row>
          {this.state.alert}
        </Container>
      </>
    );
  }
}

export default TaxInvoiceDetail;
