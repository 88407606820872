/* eslint-disable eqeqeq */
import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Button,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap";
import StandardHeader from "components/Headers/HeaderVendor";
import "../../../assets/css/custome/trackingOrder.css";
import config from "services/config";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { currencyIDR } from "services/currency";
import { uuidv5 } from "services/uuidv5";
import { webdatetime } from "services/sysdate";
var uuid = uuidv5(8);
class VendorReviewRegulerInvoice extends PureComponent {
  state = {
    national:[],
    listCurrencies: [],
    listTypePph: [],
    listTypeInvoice: [],
    setPPN: "",
    valPPN: "",
    match_pph: [],
    pros_pph: "",
    custome_pph: "",
    preview: "",
    checked: false,
    checkedPph: false,
    checkedReject: false,
    isButton: false,
    pphRate: "",
    dateSync: {
      date: "1900-01-01 00:00:00",
      dtt: "0",
      dateDay: "0",
      h: "0",
      m: "00",
      s: "00",
    },
    data: {
      id: "",
      revision:"",
      invoice_number: "",
      purchase_order: "",
      good_receipt: "",
      bast: "",
      tax_invoice: "",
      delivery_note: "",
      siuk: "",
      add1: "",
      add2: "",
      add3: "",
      invoice_number_file: "",
      purchase_order_file: "",
      good_receipt_file: "",
      bast_file: "",
      tax_invoice_file: "",
      delivery_note_file: "",
      siuk_file: "",
      invoice_type: "",
    },
    display: {
      dCurrC: "",
      dCurrCC: "",
    },
    submit: {
      user_id: config.USER_ID,
      datetime: webdatetime(),
      type_invoice: "",
      type_invoice_code: uuid,
      reject: 0,
      note: "",
      ppn: 0,
      ppn_rate: "",
      pph: 0,
      type_pph: "",
      custome_type_pph: "",
      tax: "",
      tax_rate: "",
      invoice_total: 0,
      curr: "",
    },
    isLoading: false,
    alert: null,
    paramError: {},
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.handleHoliday();
    this.handleTimeServer();
    this.getListInvoice();
    this.onChangePreview = this.onChangePreview.bind(this);
  };

  componentWillUnmount() {
    this.mounted = false;
  }
  handleTimeServer = () => {
    axios
      .post(
        config.API_URL + "/vendor/sync_server",
        { id: config.COMPANY_ID },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          this.setState(
            {
              ...this.state,
              dateSync: {
                ...this.state.dateSync,
                dateDay: res.data.data.day,
                date: res.data.data.dt,
                h: res.data.data.h,
                m: res.data.data.m,
                s: res.data.data.s,
              },
            },
            () =>
              this.state.dateSync.dateDay === 0 ||
              this.state.dateSync.dateDay === 6
                ? ""
                : this.handleTimeSync()
          );
        },
        (err) => {
          console.log(err.response);
        }
      );
  };
  handleHoliday = () => {
    try {
      axios
        .get(
          "https://raw.githubusercontent.com/guangrei/APIHariLibur_V2/main/calendar.json",
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (this.mounted) {
            this.setState({
              national: res.data,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleTimeServer = () => {
    axios
      .post(
        config.API_URL + "/vendor/sync_server",
        { id: config.COMPANY_ID },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          this.setState(
            {
              ...this.state,
              dateSync: {
                ...this.state.dateSync,
                dateDay: res.data.data.day,
                date: res.data.data.dt,
                dtt: res.data.data.dtt,
                h: res.data.data.h,
                m: res.data.data.m,
                s: res.data.data.s,
              },
            },
            () => this.handleTimeSync()
          );
        },
        (err) => {
          console.log(err.response);
        }
      );
  };
  handleTimeSync = () => {
    var dateISO = this.state.dateSync.date.substring(0, 10);
    var national = this.state.national;
    var found = Object.entries(national).find(
      ([key, value]) => key === dateISO && value.holiday === true
    );
    var simFound = found ? true : false;
    if (
      this.state.dateSync.dtt > 0 &&
      this.state.dateSync.dtt < 26 &&
      simFound === false
    ) {
      var dy = this.state.dateSync.dateDay;
      var dh = parseInt(this.state.dateSync.h);
      var setAStart = parseInt(9);
      var setAEnd = parseInt(15);
      if (dy > 0 && dy < 5) {
        if (dh >= setAStart && dh < setAEnd) {
          this.setState({ ...this.state, isButton: true });
        } else {
          this.setState({ ...this.state, isButton: false });
        }
      } else {
        this.setState({ ...this.state, isButton: false });
      }
    } else {
      this.setState({ ...this.state, isButton: false });
    }
  };
  getCurrencies = (data) => {
    axios
      .post(
        config.API_URL + "/vendor/get_currencies",
        { id:  data},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
             display:{
              ...this.state.display,
              dCurrC : res.data.data[0].code,
              dCurrCC: res.data.data[0].cc
             }
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getListInvoice = async () => {
    try {
      const response = await axios.post(
        config.API_URL + "/vendor/review_standard_invoice",
        { key: this.props.match.params.id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      this.setState({
        data: {
          id: response.data.data[0].id,
          invoice_status: response.data.data[0].invoice_status,
          invoice_info: response.data.data[0].invoice_info,
          revision: response.data.data[0].revision,

          invoice_number: response.data.data[0].invoice,
          invoice_number_file: response.data.data[0].invoice_file,
          purchase_order: response.data.data[0].purchase_order,
          good_receipt: response.data.data[0].good_receipt,
          bast: response.data.data[0].bast,
          tax_invoice: response.data.data[0].tax_invoice,
          delivery_note: response.data.data[0].delivery_note,
          siuk: response.data.data[0].siuk,
          siuk_file: response.data.data[0].siuk_file,

          is_verified: response.data.data[0].is_verified,
          invoice_amount: response.data.data[0].invoice_amount,

          curr: response.data.data[0].curr,
          ppn: response.data.data[0].ppn,
          ppn_rate: response.data.data[0].ppn_rate,
          tax_ppn: response.data.data[0].tax_ppn,
          pph: response.data.data[0].pph,
          tax_rate: response.data.data[0].tax_rate,
          tax_pph_dpp: response.data.data[0].tax_pph_dpp,
          tax_pph: response.data.data[0].tax_pph,
          set_pph: response.data.data[0].set_pph,
          pph_id: response.data.data[0].pph_id,
          invoice_total: response.data.data[0].total_invoice,

          invoice_type: response.data.data[0].invoice_type,
          status_id: response.data.data[0].status_id,

          created_on: response.data.data[0].created_on,

          param: response.data.data[0].is_reject,
          // tax_verified: response.data.data[0].tax_verified,
          // pic_tax: response.data.data[0].pic_tax,
          ap_verified: response.data.data[0].ap_verified,
          pic_ap: response.data.data[0].pic_ap,

          // updated_at_a: response.data.data[0].tax_updated_at,
          updated_at_b: response.data.data[0].updated_at,
          // note_a: response.data.data[0].tax_note,
          note_b: response.data.data[0].ap_note,
          is_receive: response.data.data[0].doc_receive,
          is_receive_date: response.data.data[0].doc_receive_date,
        },
      },()=> this.getCurrencies(this.state.data.curr));
    } catch (error) {
      // this.props.history.push("/admin/active-invoice-tax");
      console.log("cek");
    }
  };

  onChangePreview = (a) => {
    const fileUploaded = a;
    this.setState({ preview: fileUploaded });
  };
  handleCancel = () => {
    // console.log(this.props.match.params.opt)
    if (this.props.match.params.opt == 0) {
      this.props.history.push("/vendor/active-reguler-invoice");
    } else if (this.props.match.params.opt == 1) {
      this.props.history.push("/vendor/close-reguler-invoice/" + 1);
    } else if (this.props.match.params.opt == 2) {
      this.props.history.push("/vendor/close-reguler-invoice/" + 2);
    } else if (this.props.match.params.opt == 3) {
      this.props.history.push("/vendor/close-reguler-invoice/" + 3);
    } else {
      this.props.history.push("/vendor/new-notification-vendor/" + 4);
    }
  };
  handleClose = () => {
    this.setState({ preview: "" });
  };

  onChangePreview = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          title=""
          style={{ display: "block", width: "60%" }}
          onConfirm={() => this.hideAlert()}
          confirmBtnBsStyle="link"
          confirmBtnText="Close"
          // btnSize="sm"
        >
          <object
            width="100%"
            height="790"
            data={config.BUCKET_URL + data}
            type="application/pdf"
          >
            {" "}
          </object>
        </SweetAlert>
      ),
    });
  };

  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          // onConfirm={() => this.hideAlert()}
          onConfirm={() => this.handleCancel()}
          // onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
          timeout={1000}
        >
          Invoice&nbsp; <b>{`${data.data}`}</b>&nbsp;{`${data.message}`}
        </SweetAlert>
      ),
    });
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
  curr=(nm,ct,cd)=>{
    const cur = Intl.NumberFormat(ct, {
      style: "currency",
      currency: cd,
      maximumFractionDigits: 2
    }).format(nm);
    return cur
  }
  render() {
    const handleChangeInvoice = this.state.isButton ? (
      <div
        className="btn btn-sm btn-danger"
        style={{
          fontSize: "1em",
          borderRadius: "45px",
        }}
        onClick={() =>
          this.props.history.push(
            "/vendor/update-reguler-invoice/" +
              this.props.match.params.opt +
              "/" +
              this.props.match.params.id
          )
        }
      >
        <span className="fa-stack fa-lg mt--1" style={{ fontSize: "0.5em" }}>
          <i className="fa fa-certificate fa-stack-2x "></i>
          <i className="fa fa-times fa-stack-1x fa-inverse text-secondary mx-auto text-danger"></i>
        </span>{" "}
        Reject
      </div>
    ) : (
      <div
        className="btn btn-sm btn-default"
        style={{
          fontSize: "1em",
          borderRadius: "45px",
          pointerEvents: "none",
          cursor: "not-allowed !important"
        }}
      >
        <span className="fa-stack fa-lg mt--1" style={{ fontSize: "0.5em" }}>
          <i className="fa fa-certificate fa-stack-2x "></i>
          <i className="fa fa-times fa-stack-1x fa-inverse text-secondary mx-auto text-default"></i>
        </span>{" "}
        Reject
      </div>
    );

    return (
      <>
        <StandardHeader
          name="Detail"
          parentName="Close"
          link="close-reguler-invoice/tb"
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col className="col-lg-12 col-md-12" sm="12" xs="12">
              <Card className=" shadow">
                <CardBody>
                  <button
                    type="button"
                    className="close mt--2 mr--2"
                    aria-label="Close"
                    onClick={() => this.handleCancel()}
                  >
                    <span aria-hidden="true" style={{ fontSize: "1 rem" }}>
                      &times;
                    </span>
                  </button>
                  <Row className="mx-4">
                    <Col xs="12" md="9">
                      <h2 className="mb--1">
                        {this.state.data.invoice_number}
                        {this.state.data.revision > 0 && (this.state.data.invoice_info === 5 || this.state.data.invoice_info === 7 || this.state.data.invoice_info === 2 || this.state.data.invoice_info === 12 )  ? 
                        (<span className="text-primary"> R{this.state.data.revision}</span> )
                        : null}
                      </h2>
                      <small className="m-0 text-muted">{this.state.data.created_on}</small>
                      <span
                        style={{ fontSize: "0.75em" }}
                        className="text-muted"
                      ></span>
                    </Col>
                    <Col md="3" xs="12" className="my-auto text-right">
                      <Button
                        id="dl"
                        size="sm"
                        type="button"
                        style={{ objectFit: "cover", cursor: "pointer" }}
                        onClick={() =>
                          window.open(
                            config.BUCKET_URL +
                              this.state.data.invoice_number_file,
                            "_blank"
                          )
                        }
                      >
                        <i className="fa fa-download"></i>
                      </Button>
                      <UncontrolledTooltip flip delay={0} target={"dl"}>
                        Donwload File
                      </UncontrolledTooltip>
                      <UncontrolledTooltip flip delay={0} target={"pr"}>
                        Preview File
                      </UncontrolledTooltip>{" "}
                      <Button
                        size="sm"
                        type="button"
                        id="pr"
                        style={{ objectFit: "cover", cursor: "pointer" }}
                        onClick={() =>
                          this.onChangePreview(
                            this.state.data.invoice_number_file
                          )
                        }
                      >
                        <i className="fa fa-file-pdf-o"></i>
                      </Button>
                      {this.state.data.invoice_status == 2 ? (
                        this.state.data.invoice_info == 12 ? (
                          <>{handleChangeInvoice}</>
                        ) : (
                          <>
                            <div
                              className="btn btn-sm btn-success"
                              style={{
                                fontSize: "1em",
                                pointerEvents: "none",
                                borderRadius: "45px",
                              }}
                            >
                              <span
                                className="fa-stack fa-lg mt--1"
                                style={{ fontSize: "0.5em" }}
                              >
                                <i className="fa fa-certificate fa-stack-2x"></i>
                                <i className="fa fa-check fa-stack-1x fa-inverse text-secondary mx-auto text-success"></i>
                              </span>{" "}
                              Approve
                            </div>
                          </>
                        )
                      ) : (
                        <div
                          className="btn btn-sm"
                          style={{
                            fontSize: "1em",
                            pointerEvents: "none",
                            borderRadius: "42px",
                            backgroundColor: "#8965e0",
                            color: "#fff",
                          }}
                        >
                          <span
                            className="fa-stack fa-lg mt--1"
                            style={{ fontSize: "0.5em" }}
                          >
                            <i className="fa fa-circle fa-stack-2x "></i>
                            <i
                              className="fa fa-arrow-right fa-stack-1x fa-inverse mx-auto"
                              style={{ color: "#8965e0" }}
                            ></i>
                          </span>{" "}
                          On Progress
                        </div>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            {this.state.data ? (
              <>
                <Col className="col-lg-6 col-md-6" sm="12" xs="12">
                  <Card className="shadow">
                    <CardBody>
                      {/* <FormGroup> */}
                      <Row className="mx-4">
                        <Col md="6" className="my-auto text-muted">
                          Tax Invoice
                        </Col>
                        <Col md="6" className="my-auto">
                          <Label className="form-control-label">
                            {this.state.data.tax_invoice ? this.state.data.tax_invoice : " - "}
                          </Label>
                        </Col>
                      </Row>
                      {/* </FormGroup> */}
                      <hr className="mx-4 my-2" />
                      {/* <FormGroup> */}
                      <Row className="mx-4">
                        <Col md="6" className="my-auto text-muted">
                          Purchase order (PO)
                        </Col>
                        <Col md="6" className="my-auto">
                          <Label className="form-control-label">
                            {this.state.data.purchase_order ? this.state.data.purchase_order : " - "}
                          </Label>
                        </Col>
                      </Row>
                      {/* </FormGroup> */}
                      <hr className="mx-4 my-2" />
                      {/* <FormGroup> */}
                      <Row className="mx-4">
                        <Col md="6" className="my-auto text-muted">
                          Good receipt (GR)
                        </Col>
                        <Col md="6" className="my-auto">
                          <Label className="form-control-label">
                            {this.state.data.good_receipt ? this.state.data.good_receipt : " - "}
                          </Label>
                        </Col>
                      </Row>
                      {/* </FormGroup> */}
                      <hr className="mx-4 my-2" />
                      {/* <FormGroup> */}
                      <Row className="mx-4">
                        <Col md="6" className="my-auto text-muted">
                          Delivery note
                        </Col>
                        <Col md="6" className="my-auto">
                          <Label className="form-control-label">
                            {this.state.data.delivery_note ? this.state.data.delivery_note : " - "}
                          </Label>
                        </Col>
                      </Row>
                      {/* </FormGroup> */}
                      <hr className="mx-4 my-2" />
                      {/* <FormGroup> */}
                      <Row className="mx-4">
                        <Col md="6" className="my-auto text-muted">
                          Minutes of hand over
                          <small>
                            <i className="text-muted"> BAST</i>
                          </small>
                        </Col>
                        <Col md="6" className="my-auto">
                          <Label className="form-control-label">
                            {this.state.data.bast ? this.state.data.bast : " - "}
                          </Label>
                        </Col>
                      </Row>
                      <hr className="mx-4 my-2" />
                      {/* </FormGroup> */}
                    </CardBody>
                  </Card>
                  {this.state.data.siuk_file  ? (
                    <>
                      <Card className="shadow">
                        <CardBody>
                          {this.state.data.siuk_file ? (
                            <>
                              <Row className="mx-4">
                                <Col md="12" className="my-auto text-muted">
                                  Construction business license
                                  <i className="text-muted">(SIUK)
                                  </i>
                                </Col>
                              </Row>
                              <Row className="mx-4">
                                <Col md="8" xs="8" className="my-auto">
                                  {this.state.data.siuk}
                                </Col>
                                <Col
                                  md="4"
                                  xs="4"
                                  className="my-auto text-right"
                                >
                                  <Button
                                    size="sm"
                                    type="button"
                                    id="dl"
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "4px",
                                    }}
                                    onClick={() =>
                                      window.open(
                                        config.BUCKET_URL +
                                          this.state.data.siuk_file,
                                        "_blank"
                                      )
                                    }
                                  >
                                    <i className="fa fa-download"></i>
                                  </Button>
                                  <UncontrolledTooltip
                                    flip
                                    delay={0}
                                    target={"dl"}
                                  >
                                    Donwload File
                                  </UncontrolledTooltip>
                                  <UncontrolledTooltip
                                    flip
                                    delay={0}
                                    target={"pr"}
                                  >
                                    Preview File
                                  </UncontrolledTooltip>{" "}
                                  <Button
                                    size="sm"
                                    type="button"
                                    id="pr"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.onChangePreview(
                                        this.state.data.siuk_file
                                      )
                                    }
                                  >
                                    <i className="fa fa-file-pdf-o"></i>
                                  </Button>
                                </Col>
                              </Row>
                              {this.state.data.add_file1 ? (
                                <hr className="mx-4 my-2" />
                              ) : null}
                            </>
                          ) : null}
                        </CardBody>
                      </Card>
                    </>
                  ) : (
                    ""
                  )}
                </Col>
                <Col className="col-lg-6 col-md-6" sm="12" xs="12">
                  <Card className="shadow">
                    <CardBody>
                      {/* {this.state.data.invoice_type ? (
                        <h3 className="text-center">
                          <div
                            className="mt-1 btn btn-sm btn-default"
                            style={{ pointerEvents: "none" }}
                          >
                            <i className="fa fa-tag" />{" "}
                            {this.state.data.invoice_type}
                          </div>
                        </h3>
                      ) : null} */}
                      <hr className="mx-4 my-4 " />
                      {/* {this.state.data.ppn == 0 &&
                      this.state.data.pph == 0 ? null : (
                        <> */}
                      <Row className="mx-4 my-3">
                        <Col>
                          {" "}
                          <Label
                            className="form-control-label my-auto text-left"
                            style={{ fontSize: "1.24em" }}
                          >
                            Invoice amount
                          </Label>
                        </Col>
                        <Col className="text-right">
                          <Label
                            className="form-control-label"
                            style={{ fontSize: "1.24em" }}
                          >
                            <strong>
                              { this.state.display.dCurrCC && this.state.display.dCurrC ? this.curr(this.state.data.invoice_amount,this.state.display.dCurrCC,this.state.display.dCurrC) : null}
                            </strong>
                          </Label>
                        </Col>
                      </Row>
                      <hr className="mx-4 my-2 " />
                      {/* </>
                      )} */}
                      {this.state.data.ppn == 1 ? (
                        <>
                          <Row className="mx-4 my-1">
                            <Col
                              md="6"
                              className={
                                this.state.data.ppn ? null : "text-muted"
                              }
                            >
                              <span
                                style={{
                                  fontSize: "0.82em",
                                  paddingLeft: "20px",
                                }}
                              >
                                PPN
                              {" "+this.state.data.tax_ppn+'%'}
                              </span>
                              {/* {this.state.data.ppn ? (
                                <Label
                                  style={{ fontSize: "0.82em" }}
                                  className="form-control-label"
                                >
                                  {" "}
                                  <i className="fa fa-check-circle" />{" "}
                                  <span className="text-secondary">
                                    {this.state.data.tax_ppn + "%"}
                                  </span>
                                </Label>
                              ) : (
                                <Label
                                  style={{ fontSize: "0.82em" }}
                                  className="form-control-label"
                                >
                                  <i className="fa fa-times-circle text-muted" />
                                </Label>
                              )} */}
                            </Col>
                            {this.state.data.ppn && this.state.data.tax_ppn ? (
                              <Col md="6" className="text-right">
                                <Label className="form-control-label">
                                  {this.state.data.ppn_rate}
                                </Label>
                              </Col>
                            ) : null}
                          </Row>
                          {this.state.data.pph == 1 ? (
                            <>
                            {this.state.data.tax_pph_dpp > 0 ? 
                              <Row className="mx-4 my-1">
                                <Col
                                  md="6"
                                  className={
                                    this.state.data.pph ? null : "text-muted"
                                  }
                                >
                                  <span
                                    style={{
                                      fontSize: "0.82em",
                                      paddingLeft: "20px",
                                    }}
                                  >
                                    PPH DPP
                                  </span>
                                </Col>
                                {this.state.data.pph &&
                                this.state.data.tax_pph ? (
                                  <Col md="6" className="text-right">
                                    <Label className="form-control-label">
                                      {this.state.data.tax_pph_dpp}
                                    </Label>
                                  </Col>
                                ) : null}
                              </Row>
                            :null}
                              <Row className="mx-4 my-1">
                                <Col
                                  md="6"
                                  className={
                                    this.state.data.pph ? null : "text-muted"
                                  }
                                >
                                  <span
                                    style={{
                                      fontSize: "0.82em",
                                      paddingLeft: "20px",
                                    }}
                                  >
                                    PPH
                                  {" "}  {this.state.data.tax_pph + "%"}
                                  </span>
                                  {/* {this.state.data.pph ? (
                                    <Label
                                      style={{ fontSize: "0.82em" }}
                                      className="form-control-label"
                                    >
                                      {" "}
                                      <i className="fa fa-check-circle " />{" "}
                                      {this.state.data.tax_pph + "%"}
                                    </Label>
                                  ) : (
                                    <Label
                                      style={{ fontSize: "0.82em" }}
                                      className="form-control-label"
                                    >
                                      <i className="fa fa-times-circle text-muted" />
                                    </Label>
                                  )} */}
                                </Col>
                                {this.state.data.pph &&
                                this.state.data.tax_pph ? (
                                  <Col md="6" className="text-right">
                                    <Label className="form-control-label">
                                      {this.state.data.tax_rate}
                                    </Label>
                                  </Col>
                                ) : null}
                              </Row>
                            </>
                          ) : null}
                          <hr className="mx-4 my-2 " />
                        </>
                      ) : null}
                      {this.state.data.param ===3 ? (
                        <>
                         <FormGroup>
                            <Row className="mx-4 my-3">
                              <Col sm={6}>
                                <Label
                                  className="form-control-label my-auto text-left"
                                  style={{ fontSize: "1.24em" }}
                                >
                                  Invoice total
                                </Label>
                              </Col>
                              <Col sm={6}>
                                <Label className="my-auto float-right">
                                  <div
                                    // className="mr-3"
                                    style={{
                                      fontSize: "1.24em",
                                    }}
                                  >
                                    <strong>
                                    { this.state.display.dCurrCC && this.state.display.dCurrC ? this.curr(this.state.data.invoice_total,this.state.display.dCurrCC,this.state.display.dCurrC) : 0}
                                    </strong>
                                  </div>
                                </Label>
                              </Col>
                            </Row>
                          </FormGroup>
                         
                        </>
                      ):null}
                       <FormGroup>
                            <Row className="mx-4 my-3">
                              <Col sm={6}>
                                <Label
                                  className="form-control-label my-auto text-muted text-left"
                                  style={{ fontSize: "0.94em" }}
                                >
                                  Currency
                                </Label>
                              </Col>
                              <Col sm={6}>
                                <Label className="my-auto float-right text-muted">
                                  <div
                                    // className="mr-3"
                                    style={{
                                      fontSize: "0.94em",
                                    }}
                                  >
                                    <strong>
                                      
                                      { this.state.display.dCurrCC && this.state.display.dCurrC ? this.state.display.dCurrC : null}
                                    </strong>
                                  </div>
                                </Label>
                              </Col>
                            </Row>
                          </FormGroup>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <div className="mx-5 my-5">
                        {this.state.data.ap_verified === 1 ? (
                          <>
                            {this.state.data.invoice_info == 12 ? (
                              <>
                                <div className="d-flex">
                                  <div>
                                    {" "}
                                    <i className="fa fa-times-circle text-danger" />
                                    &nbsp;<b>Reject</b>{" "}
                                  </div>
                                 
                                  <div className="ml-auto">
                                    <small>
                                      <b>{this.state.data.updated_at_b}</b>
                                    </small>
                                  </div>
                                </div>
                                <div className="border-left dashed border-muted d-flex ml-2">
                                     <div className="mt-4 mb-2 ml-4 mr-2">

                                      <b className="" >
                                          {this.state.data.note_b ? (
                                            this.state.data.note_b
                                            ) : null}
                                            </b>
                                      <p className="text-muted">
                                    {this.state.data.pic_ap}
                                  </p>
                                            </div>
                                    </div>
                              </>
                            ) : (
                              <>
                                {this.state.data.is_receive == 1 &&
                                this.state.data.is_receive_date !==
                                  "0000-00-00 00:00:00" ? (
                                  <>
                                    <div className="d-flex">
                                      <div className="mr-auto">
                                        {" "}
                                        <i className="fa fa-file-archive-o text-dark" />
                                        &nbsp;
                                        <b className="text-dark">
                                          Document received
                                        </b>{" "}
                                      </div>
                                      <div>
                                        <small className="text-dark">
                                          <b>
                                            {this.state.data.is_receive_date}
                                          </b>
                                        </small>
                                      </div>
                                    </div>
                                    <div className="border-left dashed border-muted d-flex ml-2">
                                      <span className="text-muted ml-3 my-3"></span>
                                    </div>
                                    <div className="d-flex">
                                      <div>
                                        {" "}
                                        <i className="fa fa-check-circle text-muted" />
                                        &nbsp;
                                        <b className="text-muted">
                                          Approve
                                        </b>{" "}
                                      </div>
                                      <div className="ml-1">
                                        {this.state.data.pic_ap}
                                      </div>
                                      <div>
                                        <small className="text-muted ml-auto">
                                          <b>{this.state.data.updated_at_b}</b>
                                        </small>
                                      </div>
                                    </div>
                                    <div className="border-left dashed border-muted d-flex ml-2">
                                      <span className="text-muted ml-3 my-3"></span>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="d-flex">
                                      <div>
                                        {" "}
                                        <i className="fa fa-check-circle text-success" />
                                        &nbsp;
                                        <b>Approve</b>{" "}
                                      </div>
                                      <div className="ml-1">
                                        <b>{this.state.data.pic_ap}</b>
                                      </div>
                                      <div className="ml-auto">
                                        <small>
                                          <b>{this.state.data.updated_at_b}</b>
                                        </small>
                                      </div>
                                    </div>
                                    <div className="border-left dashed border-muted d-flex ml-2">
                                      <span className="text-muted ml-3 my-3"></span>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                                <div className="d-flex">
                                  <div className="mr-auto">
                                    {" "}
                                    <i className="fa fa-dot-circle-o text-dark" />
                                    &nbsp;<b className="text-dark">Approval</b>
                                  </div>
                                  <div>
                                    <small className="text-dark">
                                      <b>On Progress</b>
                                    </small>
                                  </div>
                                </div>
                                <div className="border-left dashed border-muted d-flex ml-2">
                                  <span className="text-muted ml-3 my-3"></span>
                                </div>
                          </>
                        )}
                        {/* {this.state.data.tax_verified === 1 ? (
                          <>
                            <div className="d-flex">
                              <div>
                                {" "}
                                <i className="fa fa-dot-circle-o text-muted" />
                                &nbsp;<b className="text-muted">
                                  Verified
                                </b>{" "}
                              </div>
                              <div className="ml-1 text-muted">
                                <b>{this.state.data.pic_tax}</b>
                              </div>
                              <div className="ml-auto">
                                <small className="text-muted">
                                  <b>{this.state.data.updated_at_a}</b>
                                </small>
                              </div>
                            </div>
                            <div className="border-left dashed border-muted d-flex ml-2">
                              <span className=" ml-3">
                                <ul>
                                  {this.state.data.note_a ? (
                                    <li className="mt-3">
                                      <b>{this.state.data.note_a}</b>
                                    </li>
                                  ) : null}
                                </ul>
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="d-flex">
                              <div className="mr-auto">
                                {" "}
                                <i className="fa fa-dot-circle-o text-dark" />
                                &nbsp;<b className="text-dark">Verification</b>
                              </div>
                              <div>
                                <small className="text-dark">
                                  <b>On Progress</b>
                                </small>
                              </div>
                            </div>
                            <div className="border-left dashed border-muted d-flex ml-2">
                              <span className="text-muted ml-3 my-3"></span>
                            </div>
                          </>
                        )} */}
                        <div className="d-flex">
                          <div className="mr-auto">
                            {" "}
                            <i className="fa fa-dot-circle-o text-muted" />
                            &nbsp;<b className="text-muted">
                            {this.state.data.revision > 0  ? "Revision":"Submit"}
                            </b>{" "}
                          </div>
                          <div>
                            <small className="text-muted">
                              <b>{this.state.data.created_on}</b>
                            </small>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </>
            ) : null}
          </Row>
          {this.state.alert}
        </Container>
      </>
    );
  }
}
export default VendorReviewRegulerInvoice;
