/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import {
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  Nav,
  Container,
  Badge,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import config from "services/config";
import axios from "axios";
var jwt = require("jwt-simple");
var secret = "HS256 ";
// const nD = new Date();
// const dt =
//   nD.getFullYear() +
//   "-" +
//   ("0" + (nD.getMonth() + 1)).slice(-2) +
//   "-" +
//   ("0" + nD.getDate()).slice(-2) +
//   " " +
//   ("0" + nD.getHours()).slice(-2) +
//   ":" +
//   ("0" + nD.getMinutes()).slice(-2) +
//   ":" +
//   nD.getSeconds();
// // 
//   const hm = ("0" + nD.getHours()).slice(-2) +":" +("0" + nD.getMinutes()).slice(-2) 
// console.log(dt)
// console.log(hm)

// if (not holiday) {
  // wd 
  // wd1 = 09:00 
  // wd2 = 15:00
  // fri
  // wd3 = 08:30 
  // wd4 = 11:00
// } else {

      // if (23:59)
// }


// const prefDate =
//   new Date().getFullYear().toString().substr(-2) +
//   "" +
//   ("0" + (nD.getMonth() + 1)).slice(-2) +
//   "" +
//   ("0" + nD.getDate()).slice(-2)

// const globaltime = nD.toLocaleTimeSting("Asia/Jakarta")// 
// const globaltime = new Date().toLocaleString("en-US", {
//   timeZone: "Asia/Bangkok",
// });
// const globaltime = convertTZ("2012/04/20 10:10:30 +0000", "Asia/Jakarta") 




  
class VendorNavbar extends React.Component {
  state = {
    toggleNotif: false,
    showNotif: "",
    alert: null,
    notif: [],
    national:[],
    nationalDay:"",
    total: 0,
    datetime:{
      date:"",
      hour:"",
      minutes:"",
      seconds:"",
    },
    dateDay: "",
    dateSync:"",
    showBadge: false,
    data: {
      idA: 0,
      idB: 0,
      idC: 0,
      idAD: "2023-01-01 01:00:00",
      idBD: "2023-01-01 01:00:00",
      idCD: "2023-01-01 01:00:00",
    },
  };
  componentDidMount() {
    this.mounted = true;
    this.getListNotification();
    this.updateInterval = setInterval(() => {
      // this.getListNotification();
      this.checkActive();
    }, 10800000);
    // this.running()
   
    // try {
    //       axios.get('https://raw.githubusercontent.com/guangrei/APIHariLibur_V2/main/calendar.json').then((res) => {
    //       //  var data = JSON.parse(res);
    //       console.log(res.data);
    // })
    // } catch (error) {
      
    // }

    // this.operationalInput()
    // this.handleTimeServer()
  }
  componentWillUnmount() {
    this.mounted = false;
    // clearInterval(this.updateInterval);
    // this.setTime();
  }
  // operationalInput = () => {
  //   axios
  //     .post(
  //       config.API_URL + "/vendor/list_time_operational",
  //       { id: config.COMPANY_ID },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: config.TOKEN,
  //         },
  //       }
  //     )
  //     .then(
  //       (res) => {
  //         this.setState(
  //           {
  //             dataOperational: res.data.data
  //           }
  //         );
  //       },
  //       (err) => {
  //         if (err.response !== undefined) {
  //           this.setState({ isLoading: false });
  //         }
  //       }
  //     );
  // };
  // handleTimeServer = () => {
  //   axios
  //     .post(
  //       config.API_URL + "/vendor/sync_server",
  //       { id: config.COMPANY_ID },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: config.TOKEN,
  //         },
  //       }
  //     )
  //     .then(
  //       (res) => {
  //         this.setState(
  //           {
  //             ...this.state,
  //             dateDay: res.data.data.day,
  //             dateSync: res.data.data.dt
  //           }
  //         );
  //       },
  //       (err) => {
  //         if (err.response !== undefined) {
  //           this.setState({ isLoading: false });
  //         }
  //       }
  //     );
  // };
  // handleHoliday = ()=>{
  //   //  try {
  //         axios.get('https://raw.githubusercontent.com/guangrei/APIHariLibur_V2/main/calendar.json',
  //         {
  //           headers: {
  //             "Content-Type": "application/json"
  //           },
  //         }
  //         ).then(
  //           (res) => {
  //             if (this.mounted){

  //               this.setState({
  //                 national: res.data
  //               })
  //             }
  //   })
  //   // } catch (error) {
      
  //   // }
  // }

  // running =()=>{
  //   setInterval(() => {
  //     this.setState({setTime :(new Date())})
  //    }, 1000);
     
  // }
//   setTime=()=>{
//     var currentdate = new Date();
//     var hours = currentdate.getUTCHours() + parseInt(this.props.UTCOffset);    

//       // correct for number over 24, and negatives
//       if( hours >= 24 ){ hours -= 24; }
//       if( hours < 0   ){ hours += 12; }

//       // add leading zero, first convert hours to string
//       hours = hours + "";
//       if( hours.length == 1 ){ hours = "0" + hours; }

//       // minutes are the same on every time zone
//       var minutes = currentdate.getUTCMinutes();

//       // add leading zero, first convert hours to string
//        minutes = minutes + "";
//       if( minutes.length == 1 ){ minutes = "0" + minutes; }

//      var  seconds = currentdate.getUTCSeconds();
//       console.log(hours, minutes, seconds)
//       this.setState({
//         datetime:{
// ...this.state.datetime,
//           hours: hours,
//         minutes: minutes,
//         seconds: seconds
//       }
//       });
//     }
  // 3 hour
  getListNotification = () => {
    axios
      .post(
        config.API_URL + "/vendor/notification_vendor",
        { id: config.COMPANY_ID },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          this.setState(
            {
              ...this.state,
              total:
                res.data.data.count_apr +
                res.data.data.count_rej +
                res.data.data.count_sli,
              data: {
                ...this.state.data,
                idA: res.data.data.count_apr,
                idAD: res.data.data.date_apr,
                idB: res.data.data.count_rej,
                idBD: res.data.data.date_rej,
                idC: res.data.data.count_sli,
                idCD: res.data.data.date_sli,
              },
            }
          );
        },
        (err) => {
          if (err.response !== undefined) {
            this.failedAlert(err.response.data.message);
            this.setState({ isLoading: false });
          }
        }
      );
  };
  checkActive = () => {
    var token_local = localStorage.getItem("token");
    if (!token_local){
      this.deleteAllCookies();
      localStorage.clear();
      this.props.history.push("/auth/login");
    }
  };
  handlelogout = () => {
    try {
      var token_local = jwt.decode(localStorage.getItem("token"), secret);
      axios
        .post(
          config.API_URL + "/auth/logout",
          { user_id: token_local.user_id },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(
          (res) => {
            this.deleteAllCookies();
            localStorage.clear();
            this.props.history.push("/auth/login");
          },
          (err) => {
            if (err.response !== undefined) {
              this.failedAlert(err.response.data.message);
              this.setState({ isLoading: false });
            }
          }
        );
    } catch (e) {
      console.log(e);
    }
  };

  deleteAllCookies=()=> {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
  // getListNotification = () => {
  //   axios
  //     .post(
  //       config.API_URL + "/notifications/notifications",
  //       this.state.actionListNotifications,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: config.TOKEN,
  //         },
  //       }
  //     )
  //     .then(
  //       (res) => {
  //         this.setState({
  //           listNotifications: res.data.data,
  //         });
  //         console.log(res.data.data);
  //       },
  //       (err) => {
  //         if (err.response !== undefined) {
  //           this.failedAlert(err.response.data.message);
  //           this.setState({ isLoading: false });
  //         }
  //       }
  //     );
  // };

  // getTotalNotification = () => {
  //   axios
  //     .post(
  //       config.API_URL + "/notifications/total_notifications",
  //       {},
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: config.TOKEN,
  //         },
  //       }
  //     )
  //     .then(
  //       (res) => {
  //         this.setState({
  //           total_notifications: res.data.data,
  //         });
  //       },
  //       (err) => {
  //         if (err.response !== undefined) {
  //           this.failedAlert(err.response.data.message);
  //           this.setState({ isLoading: false });
  //         }
  //       }
  //     );
  // };
  confirmAlert = (data) => {
    //   var val
    // data === "Token is not registered" ? (val = 1 ) : (val = 0)
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Logout"
          onConfirm={() => this.handlelogout()}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Yes, logout!"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
          
        >
          {data}
        </SweetAlert>
      ),
    },
    )
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.handlelogout()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
          timeout={1000}
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleToggle = () => {
    this.setState({
      showBadge : !this.state.showBadge
    },
    this.props.history.push("/vendor/new-notification-vendor/"+4)
    )
    // this.setState({...this.state, showNotif: " "}
    //   ,() => this.props.history.push("/vendor/new-notification-vendor/"+4)
    //   )
  }
  render() { 
// console.log(this.state.dateDay);
// console.log(this.state.dateSync)




// console.log(this.running())
    // const data_local = jwt.decode(localStorage.getItem("token"), secret);
    // var t = moment();
    // var m1 = moment( this.state.data.idAD, 'YYYY-MM-DD HH:mm:ss');
    // var m2 = moment( new).format("YYYY-MM-DD HH:mm:ss");
    // var m3 = m2.diff(m1,'minutes');
    // var m4 = m2.diff(m1,'h');
    // var numdays = Math.floor(m3 / 1440);
    // var numhours = Math.floor((m3 % 1440) / 60);
    // var numminutes = Math.floor((m3 % 1440) % 60);
    // numdays + " day(s) " + numhours +"h " + numminutes +"m";
    
    // var today = moment().format("YYYY-MM-DD");
    // var ep = moment.unix(todays)  

    // var t = today;
    // var t2 = todays;
    // var b = moment(this.state.data.idAD).format("YYYY-MM-DD")

    // var k = t.duration(b, 'days')
    // console.log(cek+" | "+ b)
    // console.log(t)
    
    // var m2 = moment().format("YYYY-MM-DD HH:mm:ss");
    // var m2 = moment().format("YYYY-MM-DD HH:mm:ss");
    // var m1 = this.state.data.idAD;
    // var m2 = moment(m1 ,"YYYY-MM-DD HH:mm:ss")


    // var m3 = m2.diff(m1,'minutes');
    // var m4 = m2.diff(m1,'h');
    // var numdays = Math.floor(m3 / 1440);
    // var numhours = Math.floor((m3 % 1440) / 60);
    // var numminutes = Math.floor((m3 % 1440) % 60);
    // numdays + " day(s) " + numhours +"h " + numminutes +"m";
    // var m3 = moment.unix(m1)

    // var m2 = moment().unix();
    // var m3 = m2 - m1
    // var m4 = moment.unix(m3);
    
    // var c 
    // if (m3 < 86400){
    //   // hour 
    //         c = 
    // } else if (m3 < 3600) {
    //   // min 

    // } else {
    //     // days 

    //   }
    // console.log(numdays + " day(s) " + numhours +"h " + numminutes +"m")
    // var myObject = this.state.national

    // Object.keys(myObject).forEach(function(key, index) {
    //   myObject[key]. 
    // });
           
            
    // var cek = objectMap(this.state.national,
    //   (dat){
    //     return dat.date
    //   }
    // )
    
    // var cek = Array.from(this.state.national).map(row => row)
    // var cek = JSON.parse(this.state.national)
    // console.log(this.state.datesync)

    // if (this.state.)
    // for (const [key, value] of Object.entries(this.state.national)) {
    //   console.log(key)
    //   console.log(value.summary[0])
    // }

      // console.log(this.state.dateSync.substring(0,10))
      // console.log(this.state.dateSync.substring(0,10))

      // console.log(this.state.dateSync.substring(0,10))


      // var your_date_object = new Date();
      // your_date_object.setTime(Date.parse( this.state.data ));
      
      // var min = your_date_object.getUTCMinutes();
      // var hour = your_date_object.getUTCHours();

    return (
      <>
        <Navbar
          className={classnames(
            "navbar-top navbar-expand border-bottom",
            // { "navbar-dark bg-info": this.props.theme === "dark" },
            { "navbar-light bg-secondary": this.props.theme === "light" }
          )}
        >
          <Container fluid>
            <Collapse navbar isOpen={true}>
              <Nav className="fixed-left" navbar>
                <h4 className="text-uppercase">E-Invoice</h4>
              </Nav>
              <Nav className="align-items-center ml-md-auto" navbar>
                <NavItem className="d-xl-none">
                  <div
                    className={classnames(
                      "pr-3 sidenav-toggler",
                      { active: this.props.sidenavOpen },
                      { "sidenav-toggler-dark": this.props.theme === "light" }
                    )}
                    onClick={this.props.toggleSidenav}
                  >
                    <div className="sidenav-toggler-inner">
                      <i className="sidenav-toggler-line text-black" />
                      <i className="sidenav-toggler-line text-black" />
                      <i className="sidenav-toggler-line text-black" />
                    </div>
                  </div>
                </NavItem>
              </Nav>
              <Nav className="align-items-center ml-auto ml-md-0" navbar>
                <Media className="align-items-center">
                  <Media className="ml-2 d-none d-lg-block">
                    <UncontrolledDropdown nav
                    direction="left"
                    >
                      <DropdownToggle
                        className="nav-link pr-0"
                        color=""
                        tag="a"
                      >
                        <span>
                          {this.state.total > 0 ? (
                            <>
                              <i
                                className="ni ni-bell-55"
                                style={{
                                  cursor: "pointer",
                                  objectFit: "cover",
                                }}
                                onClick={() => {
                                  this.handleToggle()
                                }}
                                // onClick={() => this.confirmAlert("Are you sure?")}
                              >
                                <Badge
                                  className="badge-circle"
                                  style={{
                                    position: "relative",
                                    top: "-10px",
                                    right: "6px",
                                    border: "#FFFFFF 1px solid",
                                    marginRight: "-18px",
                                    backgroundColor: "#FCD98C",
                                    fontSize: "11px"
                                  }}
                                >
                                  {this.state.showBadge ? "" :(<>
                                  
                                  <span
                                    className="text-default my-auto"
                                    style={{ fontFamily: "Arial, san-serif",fontWeight: "lighter" }}
                                    >
                                    {this.state.total > 99 ? "+99" : this.state.total}
                                  </span>
                                    </>)}
                                </Badge>
                              </i>
                            </>
                          ) : (
                            <>
                              <i
                                className="ni ni-bell-55"
                                // style={{
                                //   cursor: "pointer",
                                //   objectFit: "cover",
                                // }}
                                // onClick={() => this.confirmAlert("Are you sure?")}
                              >
                                {/* <Badge
                                  className="badge-circle"
                                  style={{
                                    position: "relative",
                                    top: "-10px",
                                    right: "6px",
                                    border: "#FFFFFF 1px solid",
                                    marginRight: "-18px",
                                    backgroundColor: "#B0B7C0",
                                  }}
                                >
                                  <strong
                                    className="text-white mt--1"
                                    style={{ fontSize: "1em" }}
                                  >
                                    {this.state.total}
                                  </strong>
                                </Badge> */}
                              </i>
                            </>
                          )}
                        </span>
                      </DropdownToggle>
                      {/* <DropdownMenu right className={"hidden" + (this.state.showNotif)} >
                        <div
                          style={{
                            display: "inline-block",
                            width: "24em",
                            height: "17em",
                          }}
                        >
                            {this.state.data.idA < 1 && this.state.data.idB < 1 && this.state.data.idC < 1 ? (
                              <>
                              <div 
                              style={{
                                   textAlign: "center",
                                   marginTop: "30%"
                              }}
                              >
                                <h4 className="text-muted"> <b>
                                  No recent notifications
                                  </b>
                                  </h4>
                                </div>
                              </>
                            ) : (
                              <>
                              <div
                                className="timeline timeline-one-side mx-5 my-5"
                                data-timeline-axis-style="dashed"
                                data-timeline-content="axis"
                                style={{
                                  cursor: "pointer",
                                  objectFit: "cover",
                                }}
                                onClick={() => {
                                  this.handleToggle()
                                }}
                              >
                                {this.state.data.idA ? (
                                  <>
                                  <div className="d-flex flex-row justify-content-between font-weight-bold my-4">
                                    <div>
                                    <span className="timeline-step badge-success mt-2">
                                        <i className="fa fa-check-circle" />
                                      </span>
                                    </div>
                                    <div className="d-flex flex-column ml--5">
                                  Approve 
                                  <small>  {this.state.data.idAD}</small>
                                    </div>
                                    <div className="mt-2">
                                  {this.state.data.idA}
                                    </div>
                                  </div>
                                  </>
                                ) : null}
                                {this.state.data.idB ? (
                                 <>
                                 <div className="d-flex flex-row justify-content-between font-weight-bold my-4">
                                   <div>
                                   <span className="timeline-step badge-danger mt-2">
                                       <i className="fa fa-times-circle" />
                                     </span>
                                   </div>
                                   <div className="d-flex flex-column ml--5">
                                 Reject
                                 <small>  {this.state.data.idBD}</small>
                                   </div>
                                   <div className="mt-2">
                                 {this.state.data.idB}
                                   </div>
                                 </div>
                                 </>
                                ) : null}
                                {this.state.data.idC ? (
                                  <>
                                  <div className="d-flex flex-row justify-content-between font-weight-bold my-4">
                                   <div>
                                   <span className="timeline-step badge- mt-2">
                                       <i className="fa fa-paperclip" />
                                     </span>
                                   </div>
                                   <div className="d-flex flex-column ml--5">
                                 Slip
                                 <small>  {this.state.data.idCD}</small>
                                   </div>
                                   <div className="mt-2">
                                 {this.state.data.idC}
                                   </div>
                                 </div>
                                  </>
                                ) : null}
                          </div>
                              </>
                            )}
                        </div>
                      </DropdownMenu> */}
                    </UncontrolledDropdown>
                    &nbsp;
        {/* <span className="city-time">{globaltime}</span> */}
        {/* <span className="city-time">{this.state.hours}:{this.state.minutes}:{this.state.seconds}</span> */}
                    <span className="mb-0 text-sm font-weight-bold">
                      {/* <i className="ni ni-single-02" /> */}
                      &emsp;
                      {config.USERNAME}
                    </span>
                    &emsp;
                    <i
                      className="fa fa-power-off"
                      style={{
                        cursor: "pointer",
                        objectFit: "cover",
                      }}
                      onClick={() => this.confirmAlert("Are you sure?")}
                    />
                    &emsp;
                  </Media>
                </Media>
              </Nav>
            </Collapse>
          </Container>
          {this.state.alert}
        </Navbar>
      </>
    );
  }
}
VendorNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
VendorNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default VendorNavbar;
