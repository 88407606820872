import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "assets/vendor/bootstrap-rtl/bootstrap-rtl.scss";
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-pro-react.scss?v1.1.0";

import config from "services/config";
import axios from "axios";
import decode from "jwt-decode";
import { createStore, combineReducers } from "redux";
import { Provider } from "react-redux";

import AdminLayout from "layouts/Admin";
import ApLayout from "layouts/Ap";
import TaxLayout from "layouts/Tax";
import AuthLayout from "layouts/Auth";
import Vendor from "layouts/Vendor";
// import Hc from "layouts/Hc";
import Forwarder from "layouts/Forwarder";

import companyReducer from "views/Admin/Companies/Redux/companyReducer";
import userReducer from "views/Admin/User/Redux/userReducer";


console.warn = () => {};

const checkAuthAdmin = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    return false;
  }
  try {
    var token_local = decode(token);
    var exp = decode(token_local.token);
    var currentTime = new Date().getTime() / 1000;
    if (exp.exp < currentTime) {
      axios
      .post(config.API_URL + "/auth/logout",{ user_id: token_local.user_id}, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      // localStorage.removeItem("token");
      localStorage.clear();
      window.location.reload(true);
      return false;
    }
  } catch (e) {
    return false;
  }
  return true;
};
// const checkAuthHc = () => {
//   const token = localStorage.getItem("token");
//   if (!token) {
//     return false;
//   }
//   try {
//     var token_local = decode(token);
//     var exp = decode(token_local.token);
//     var currentTime = new Date().getTime() / 1000;
//     if (exp.exp < currentTime) {
//       axios
//       .post(config.API_URL + "/auth/logout",{ user_id: token_local.user_id}, {
//         headers: {
//           "Content-Type": "application/json"
//         },
//       })
//       // localStorage.removeItem("token");
//       localStorage.clear();
//       window.location.reload(true);
//       return false;
//     }
//   } catch (e) {
//     return false;
//   }
//   return true;
// };
const checkAuthForwarder = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    return false;
  }
  try {
    var token_local = decode(token);
    var exp = decode(token_local.token);
    var currentTime = new Date().getTime() / 1000;
    if (exp.exp < currentTime) {
      axios
      .post(config.API_URL + "/auth/logout",{ user_id: token_local.user_id}, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      // localStorage.removeItem("token");
      localStorage.clear();
      window.location.reload(true);
      return false;
    }
  } catch (e) {
    return false;
  }
  return true;
};
const checkAuthTax = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    return false;
  }
  try {
    var token_local = decode(token);
    var exp = decode(token_local.token);
    var currentTime = new Date().getTime() / 1000;
    if (exp.exp < currentTime) {
      axios
      .post(config.API_URL + "/auth/logout",{ user_id: token_local.user_id}, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      // localStorage.removeItem("token");
      localStorage.clear();
      window.location.reload(true);
      return false;
    }
  } catch (e) {
    return false;
  }
  return true;
};
const checkAuthAp = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    return false;
  }
  try {
    var token_local = decode(token);
    var exp = decode(token_local.token);
    var currentTime = new Date().getTime() / 1000;
    if (exp.exp < currentTime) {
      axios
      .post(config.API_URL + "/auth/logout",{ user_id: token_local.user_id}, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      // localStorage.removeItem("token");
      localStorage.clear();
      window.location.reload(true);
      return false;
    }
  } catch (e) {
    return false;
  }
  return true;
};

const checkRole  = (role)  =>  {
  try {
    const token = localStorage.getItem("token");
    var token_local = decode(token);
    var token_result = decode(token_local.token);
    if(token_result.role_id === role){
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuthAdmin() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/auth/home" }} />
      )
    }
  />
);
const TaxRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuthTax() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/auth/tax" }} />
      )
    }
  />
);
const ApRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuthAp() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/auth/ap" }} />
      )
    }
  />
);
const VendorRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuthAdmin() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/auth/login" }} />
      )
    }
  />
);
// const HcRoute = ({ component: Component, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) =>
//       checkAuthHc() ? (
//         <Component {...props} />
//       ) : (
//         <Redirect to={{ pathname: "/auth/hc" }} />
//       )
//     }
//   />
// );
const ForwarderRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuthForwarder() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/auth/forwarder" }} />
      )
    }
  />
);

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuthAdmin() ? (
        <Redirect to={{ pathname: "/auth/login" }} />
      ) : (
        <Component {...props} />
      )
    }
  />
);

const rootReducer = combineReducers({
  company: companyReducer,
  user: userReducer,
});

const storeRedux = createStore(rootReducer);

ReactDOM.render(
  <Provider store={storeRedux}>
    <BrowserRouter>
      <Switch>
        <AuthRoute path="/auth" component={AuthLayout} />
        <AdminRoute path="/admin" component={AdminLayout} />
        <TaxRoute path="/tax" component={TaxLayout} />
        <ApRoute path="/ap" component={ApLayout} />
        <VendorRoute path="/vendor" name="vendor" component={Vendor} />
        {/* <HcRoute path="/hc" component={Hc} /> */}
        <ForwarderRoute path="/forwarder" component={Forwarder} />
        <Redirect path="/" to="/vendor" />
      </Switch>
    </BrowserRouter>
  </Provider>
  ,
  document.getElementById("root")
);
