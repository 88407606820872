import React, { PureComponent } from "react";
import {
  Button,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import config from "services/config";
import axios from "axios";
import AuthHeader from "components/Headers/AuthHeader";
import SweetAlert from "react-bootstrap-sweetalert";
// import { styleInputSelect } from "services/styles";
// import AsyncSelect from "react-select/async";
import { validateCreateNewAccount } from "services/validate";

class RecoveryAccount extends PureComponent {
  state = {
    data: {
      id: '',      
      email: '',
      password: '',
      confirm_password: '',
    },
    actionListCompanies: {
      company_name: "",
    },
    selectedOptionCompanies: [],
    authError: false,
    isLoading: false,
    paramError: {},
    token: null,
    alert: null,
    setForget: false,
  };
  // fetchDataCompanies = (inputValue) => {
  //   let searchTerm = inputValue;

  //   if (!inputValue || inputValue === " ") {
  //     searchTerm = "";
  //   }

  //   this.setState({
  //     actionListCompanies: {
  //       ...this.state.actionListCompanies,
  //       company_name: searchTerm,
  //     },
  //   });

  //   const newRequest = axios.post(
  //     config.API_URL + "/companies/companies_public",
  //     {id : this.props.match.params.id},
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: config.TOKEN,
  //       },
  //     }
  //   );

  //   if (newRequest) {
  //     return newRequest.then((response) => {
  //       this.setState({
  //         company_id: response.data.data[0].company_id ,
  //         company_name: response.data.data[0].company_name
  //       })
  //       const compare = response.data.data.filter((i) =>
  //         i.company_name.toLowerCase().includes(inputValue.toLowerCase())
  //       );
  //       return compare.map((data) => ({
  //         label: data.company_name,
  //         value: data.company_id,
  //       }));
  //     });
  //   }
  // };
  // onSearchChangeCompanies = (selectedOptionCompanies) => {
  //   this.setState({
  //     selectedOptionCompanies: selectedOptionCompanies,
  //     data: {
  //       ...this.state.data,
  //       company_id: selectedOptionCompanies.value,
  //     },
  //   });
  // };

 componentDidMount = async () => {
  this.props.history.push('/auth/login');
}
  handleEnter(event) {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }
   handleChange  = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };
  // handleSubmit = () => {
  //   this.setState({ isLoading: true });
  //   const paramError = validateCreateNewAccount(this.state.data);
  //   this.setState({ paramError });
  //   if (Object.keys(paramError).length === 0) {
  //     const headers = {
  //       "Content-Type": "application/json",
  //     };

  //     axios.post(config.API_URL + "/users/recovery_account", this.state.data, headers).then(
  //       (res) => {
  //         this.successAlert(res.data.message);
  //         this.setState({ 
  //           isLoading: false,
  //           data: {
  //             company_id: '',
  //             username: '',
  //             email: '',
  //             password:'',
  //             confirm_password: ''
  //           },
  //          });
  //       },
  //       (err) => {
  //         this.setState({ authError: true, isLoading: false });
  //         this.failedAlert("Invalid Credentials");
  //       }
  //     );
  //   } else {
  //     this.setState({ isLoading: false });
  //   }
  // };
  // handleVerify = () => {
  //   try{
  //     if (this.state.data.email){
  //     const newRequest = axios.post(
  //       config.API_URL + "/companies/email_verification",
  //       {id :"9829"},
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: config.TOKEN,
  //           },
  //         }
  //         );
  //         if (newRequest) {
  //           return newRequest.then((response) => {
  //             this.setState({
  //               data:{
  //                 id    : response.data.data[0].id,
  //                 email  : response.data.data[0].email 
  //               },
  //               setForget : true,
  //               }
  //             )
  //           })
  //         } else {
  //           this.props.history.push("/aunt/login");
  //         }
  //       }
  // } catch (error) {
  //   this.failedAlert(error);
  //   // this.props.history.push("/aunt/login");
  // }
  // };
  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.hideAlert()}
          // onConfirm={this.props.history.push("/aunt/login")}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          
          btnSize=""
        >
          {`${data}` }
          Your account has been created please check email to verification account 
        </SweetAlert>
      ),
    });
  };
  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  keyUp = (event) => {
    event.preventDefault();
    const keyCode = event.keyCode || event.which;
    if (keyCode === 13) {
      this.handleSubmit();
    }
  };
  handleToLogin = () => {
    this.props.history.push('/auth/login');
  }
  render() {
    const paramError = this.state.paramError;
    // console.log(this.state.data)
    return (
      <div style={{height: `1000px`}}>
        <AuthHeader title="Recovery Account" lead="Microsite E-Invoice"/>
        <Container className="my-auto">
        {this.state.setForget ?
        (<>
    
          <Row className="justify-content-center">
            <Col lg="4" md="7">
              <Form role="form">
                {/* <FormGroup>
                   <AsyncSelect
                      cacheOptions
                      defaultOptions
                      styles={styleInputSelect}
                      value={this.state.selectedOptionCompanies}
                      placeholder={<div>Select</div>}
                      loadOptions={this.fetchDataCompanies}
                      onChange={(e) => {
                        this.onSearchChangeCompanies(e);
                      }}
                    />
                </FormGroup> */}
                <FormGroup>
                  {/* <label className="form-control-label text-white">Vendor</label> */}
                  <Input
                    type="text"
                    value={this.state.data.company_name}
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label text-white">Username</label>
                  <Input
                    type="text"
                    onKeyDown={this.handleEnter}
                    name="username"
                    placeholder="Username"
                    value={this.state.data.username}
                    onChange={this.handleChange}
                    required
                    className={
                      this.state.paramError.username ? "is-invalid" : ""
                    }
                  />
                  <div className="invalid-feedback">
                    {this.state.paramError.username}
                  </div>
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label text-white">Email</label>
                  <Input
                    type="email"
                    onKeyDown={this.handleEnter}
                    name="email"
                    placeholder="Email"
                    value={this.state.data.email}
                    onChange={this.handleChange}
                    required
                    className={
                      this.state.paramError.email ? "is-invalid" : ""
                    }
                  />
                  <div className="invalid-feedback">
                    {this.state.paramError.email}
                  </div>
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label text-white">Password</label>
                  <Input
                    type="password"
                    onKeyDown={this.handleEnter}
                    name="password"
                    placeholder="Password"
                    value={this.state.data.password}
                    onChange={this.handleChange}
                    required
                    className={
                      this.state.paramError.password ? "is-invalid" : ""
                    }
                  />
                  <div className="invalid-feedback">
                    {this.state.paramError.password}
                  </div>
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label text-white">Confirm Password</label>
                  <Input
                    type="password"
                    onKeyDown={this.handleEnter}
                    name="confirm_password"
                    placeholder="Password"
                    value={this.state.data.confirm_password}
                    onChange={this.handleChange}
                    required
                    className={
                      this.state.paramError.confirm_password ? "is-invalid" : ""
                    }
                  />
                  <div className="invalid-feedback">
                    {this.state.paramError.confirm_password}
                  </div>
                </FormGroup>
                <div style={{ marginTop: 40 }} className="text-center">
                  <Button
                    block
                    className="text-uppercase my-4"
                    color="primary"
                    onClick={this.handleSubmit}
                    disabled={this.state.isLoading ? true : false}
                  >
                    {this.state.isLoading ? (
                      <>
                        <span className="btn-inner--text">
                          <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                          Sent to email
                        </span>
                      </>
                    ) : (
                      <span className="btn-inner--text">Create</span>
                    )}
                  </Button>
                </div>
              </Form>
              <Row className="mt-3">
                <Col xs="6">
                  <a
                    className="text-white"
                    style={{cursor: 'pointer'}}
                    onClick={() => this.handleToLogin()}
                  >
                    <small><u>Back to login</u></small>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </>) 
        :  
        (<>
           <Row className="justify-content-center">
            <Col lg="4" md="7">
              <Form role="form">
               
                <FormGroup>
                  <label className="form-control-label text-white">Email</label>
                  <Input
                    type="email"
                    onKeyDown={this.handleEnter}
                    name="email"
                    placeholder="Email"
                    value={this.state.data.email}
                    onChange={this.handleChange}
                    required
                    className={
                      this.state.paramError.email ? "is-invalid" : ""
                    }
                  />
                  <i className="fa fa-chevron-circle-right text-light fa-fa2x"/>
                  
                     {/* {this.state.isLoading ? (
                      <>
                        <span className="btn-inner--text">
                          <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                        </span>
                      </>
                    ) : (
                      <span className="btn-inner--text">Create</span>
                    )}
                  <div className="invalid-feedback">
                    {this.state.paramError.email}
                  </div> */}
                </FormGroup>
                {/* <div style={{ marginTop: 40 }} className="text-center">
                  <Button
                    block
                    className="text-uppercase my-4"
                    color="primary"
                    onClick={this.handleVerify}
                    disabled={this.state.isLoading ? true : false}
                  >
                    {this.state.isLoading ? (
                      <>
                        <span className="btn-inner--text">
                          <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                          Sent to email
                        </span>
                      </>
                    ) : (
                      <span className="btn-inner--text">Create</span>
                    )}
                  </Button>
                </div> */}
              </Form>
              <Row className="mt-3">
                <Col xs="6">
                  <a
                    className="text-white"
                    style={{cursor: 'pointer'}}
                    onClick={() => this.handleVerify()}
                  >
                    <small><u>Back to login</u></small>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
        )}
    </Container>
        {this.state.alert}
      </div>
    );
  }
}

export default RecoveryAccount;
