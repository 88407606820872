import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Col,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  InputGroup,
} from "reactstrap";
import StandardHeader from "components/Headers/HeaderVendor";
// import { validateFilterPeriod } from "services/validate";
import config from "services/config";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import ListInvoice from "./VendorListInvoiceReguler";
var moment = require("moment");
// var Holidays = require('date-holidays');
// var hd = new Holidays();

class VendorInvoiceReguler extends PureComponent {
  state = {
    listInvoiceVendor: [],
    listInvoiceStatusActivity: [],
    revision: "",
    isFilter: false,
    isFilterLoading: false,
    isResetLoading: false,
    show_custome: true,
    isButton: false,
    listStatus: [],
    national:[],
    dateOperational:[],
    select_period: "1",
    select_status: "1",
    min_date: "",
    max_date: "",
    dateDay: "",
    dateSync: {
      date:"1900-01-01 00:00:00",
      dt:"",
      h:"00",
      m:"00",
      s:"00",
    },
    actionFilter: {
      revision: 0,
      user_id: config.USER_ID,
      company_id: config.COMPANY_ID,
      invoice: "",
      // from_date: moment().subtract(1, "years").format("YYYY-MM-DD"),
      // to_date: moment().add(1, "days").format("YYYY-MM-DD"),
    },
    isLoading: false,
    // paramError: {},
    alert: null,
    showActivity: false,
    dataDelivery: {
      company_id: config.COMPANY_ID,
      user_id: config.USER_ID,
      idInvoice: "",
      idDelete: "",
    },
    activity: {
      select_id: "",
    },
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.getListInvoiceVendor();
    // this.handleOperational();
    this.handleTimeServer();
    this.handleHoliday();
    this.setState({
      listStatus: [
        {
          value: "1",
          label: "Active",
        },
        {
          value: "2",
          label: "Verified",
        },
      ],
    });
  };

  componentWillUnmount() {
    this.mounted = false;
  }
  // handleOperational = () => {
  //   axios
  //     .post(
  //       config.API_URL + "/vendor/list_time_operational",
  //       { id: config.COMPANY_ID },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: config.TOKEN,
  //         },
  //       }
  //     )
  //     .then(
  //       (res) => {
  //         this.setState({
  //           dateOperational: res.data.data
  //           // dateOperational:{
  //           //   ...this.state.dateOperational,
  //           //   sdt: res.data.data[0].start_date,
  //           //   sd: res.data.data[0].start_description,
  //           //   sh: res.data.data[0].start_detail,
  //           //   edt: res.data.data[0].end_date,
  //           //   ed: res.data.data[0].end_description,
  //           //   eh: res.data.data[0].end_detail,
  //           //   h: res.data.data[0].opt,
  //           // }
  //         }
  //         // ,()=>this.state.dateSync.dateDay === 0 || this.state.dateSync.dateDay === 6 ?  
  //         // ""
  //         // : 
  //         ,()=>this.handleTimeSync()
  //         )
  //       },
  //       (err) => {
  //         console.log(err.response);
  //       }
  //     );
  // };
  handleTimeServer = () => {
    axios
      .post(
        config.API_URL + "/vendor/sync_server",
        { id: config.COMPANY_ID },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          this.setState({
            ...this.state,
            dateSync:{
              ...this.state.dateSync,
              dateDay: res.data.data.day,
              date: res.data.data.dt,
              dtt: res.data.data.dtt,
              h: res.data.data.h,
              m: res.data.data.m,
              s: res.data.data.s,
            }
          },()=>this.handleTimeSync()
          // ,()=>this.state.dateSync.dateDay === 0 || this.state.dateSync.dateDay === 6 ?  
          // ""
          // : 
          )
        },
        (err) => {
          console.log(err.response);
        }
      );
  };
  handleHoliday = () => {
    try {
      axios
        .get(
          "https://raw.githubusercontent.com/guangrei/APIHariLibur_V2/main/calendar.json",
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (this.mounted) {
            this.setState({
              national: res.data,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  actionButton = () => {
    this.setState({});
  };
  getListInvoiceVendor = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    // const paramError = validateFilterPeriod(this.state.actionFilter);
    // this.setState({ paramError });
    // if (Object.keys(paramError).length === 0) {
    axios
      .post(
        config.API_URL + "/vendor/list_invoice_vendor",
        this.state.actionFilter,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listInvoiceVendor: res.data.data,
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  handleDetails = (e) => {
    this.props.history.push("/vendor/review-reguler-invoice/" + 0 + "/" + e.id);
  };
  getListActivityRevision = () => {
    axios
      .post(
        config.API_URL + "/vendor/list_vendor_invoice_reguler_activity_revision",
        this.state.activity,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listInvoiceStatusActivity: res.data.data,
              revision: res.data.r,
              showActivity: !this.state.showActivity,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getListActivity = () => {
    axios
      .post(
        config.API_URL + "/vendor/list_vendor_invoice_reguler_activity",
        this.state.activity,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listInvoiceStatusActivity: res.data.data,
              revision: res.data.r,
              showActivity: !this.state.showActivity,
            },() => console.log(this.state.showActivity));
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  hideModal = () => {
    this.setState({
      showActivity: false,
      activity: {
        select_id: "",
      },
    });
  };

  handletoAdd = () => {
    this.props.history.push("/vendor/add-reguler-invoice");
  };
  handleFilter = () => {
    this.getListInvoiceVendor();
  };

  handleReset = () => {
    this.setState(
      {
        actionFilter: {
          ...this.state.actionFilter,
          status: "1",
          invoice: "",
          po: "",
          // from_date: moment().subtract(1, "years").format("YYYY-MM-DD"),
          // to_date: moment().add(1, "days").format("YYYY-MM-DD"),
        },
        select_status: "1",
        show_custome: false,
        select_period: "1",
      },
      () => {
        if (this.state.isResetLoading === false) {
          this.setState(
            {
              isResetLoading: true,
            },
            () => {
              this.getListInvoiceVendor();
            }
          );
        }
      }
    );
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  handleChangeString = (event) => {
    this.setState(
      {
        actionFilter: {
          ...this.state.actionFilter,
          [event.target.name]: event.target.value,
        },
      },
      () => {
        this.getListInvoiceVendor();
      }
    );
  };
  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data.message}`}
        </SweetAlert>
      ),
    });
  };
  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data.data}`}
        </SweetAlert>
      ),
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };


  handleTimeSync=()=>{
    var dateISO = this.state.dateSync.date.substring(0,10)
    var national = this.state.national
    var found = Object.entries(national).find(([key,value]) => key === dateISO && value.holiday === true)
    var simFound = found ? true : false
    var dy = this.state.dateSync.dateDay
    var dh = parseInt(this.state.dateSync.h)
    var setAStart = parseInt(9);
    var setAEnd = parseInt(15);
    if (this.state.dateSync.dtt > 0 && this.state.dateSync.dtt < 26 && simFound === false ){
      if (dy > 0 && dy < 5 ){
        if (dh >= setAStart && dh < setAEnd) {
          this.setState({...this.state, isButton : true
          })
        } else {
          this.setState({...this.state, isButton : false })
        }
      }else {
        this.setState({...this.state, isButton : false })
      }
    } else {
      this.setState({...this.state, isButton : false })
    }
  }
    
  render() {
    const buttonInvoice = this.state.isButton ? (
      <>
        <Button
          color="success"
          type="button"
          className="btn-icon text-right"
          onClick={() => this.handletoAdd()}
          size="sm"
        >
          <span className="btn-inner--icon mr-1">
            <i className="ni ni-fat-add fa-lg" />
          </span>
          Add
        </Button>
      </>
    ) : (
      <Button
      color="default"
        type="button"
        className="btn-icon text-right"
        size="sm"
        disabled
        style={{
          pointerEvents: "none",
          cursor: "not-allowed !important"
        }}
      >
        <span className="btn-inner--icon mr-1">
          <i className="ni ni-fat-add fa-lg" />
        </span>
        Add
      </Button>
    );
    return (
      <>
        <StandardHeader
          name="Open"
          parentName="Invoice"
          link="active-reguler-invoice"
        />
        <Container className="mt--6" fluid>
          <Modal
            centered
            fullscreen=""
            size="lg"
            isOpen={this.state.showActivity}
            toggle={this.handleStatusDetails}
          >
            <ModalHeader toggle={this.hideModal}>Approval status</ModalHeader>
            <ModalBody>
              <Table responsive size="sm" borderless>
                {this.state.listInvoiceStatusActivity.map((listAct, index) => {
                  if (listAct.status_id === 12 ) {
                    return (
                      <>
                      <tr key={index}>
                          <td
                            className="text-muted text-center"
                            style={{ width: "10%" }}
                          >
                            {moment(listAct.created_at).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                          <td className="text-center" style={{ width: "60%" }}>
                            &nbsp;
                          </td>
                          <td
                            style={{
                              backgroundColor: "#F6F6F6",
                              borderRadius: "5px",
                              textAlign: "center",
                              width: "30%",
                            }}
                          >
                            <b>Reject</b>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={1}>&nbsp;</td>
                          <td colSpan={2}>{listAct.note}</td>
                        </tr>
                      </>
                    );
                  } else if (
                    listAct.status_id === 5 ||
                    listAct.status_id === 2 
                   
                  ) {
                    return (
                      <>
                        <tr key={index} className="color">
                          <td
                            colspan={3}
                            className="text-center mx-0 "
                            style={{
                              backgroundColor: "#4B709A",
                              borderRadius: "5px",
                            }}
                          >
                            <h4 style={{ color: "white" }}>ON PROGRESS</h4>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>&nbsp;</td>
                        </tr>
                      </>
                    );
                  }  else if (listAct.status_id === 1) {
                    return (
                      <>
                        <tr key={index}>
                          <td
                            className="text-muted text-center"
                            style={{ width: "10%" }}
                          >
                            {moment(listAct.created_at).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                          <td className="text-center" style={{ width: "60%" }}>
                            &nbsp;
                          </td>
                          <td
                            style={{
                              backgroundColor: "#F6F6F6",
                              borderRadius: "5px",
                              textAlign: "center",
                              width: "30%",
                            }}
                          >
                            <b>Submit Invoice</b>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>&nbsp;</td>
                        </tr>
                      </>
                    );
                  } else if (listAct.status_id === 4) {
                    return (
                      <>
                        <tr key={index}>
                          <td
                            className="text-muted text-center"
                            style={{ width: "10%" }}
                          >
                            {moment(listAct.created_at).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                          <td className="text-center" style={{ width: "60%" }}>
                            &nbsp;
                          </td>
                          <td
                            style={{
                              backgroundColor: "#F6F6F6",
                              borderRadius: "5px",
                              textAlign: "center",
                              width: "30%",
                            }}
                          >
                            <b>Revision Invoice</b>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={1}>&nbsp;</td>
                          <td colSpan={2}>{'R'+this.state.revision}</td>
                        </tr>
                      </>
                    );
                  }
                })}
              </Table>
            </ModalBody>
          </Modal>
          {/* <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="6">
                  <h3 className="mb-0">Filter</h3>
                </Col>
                <Col xs="6" className="text-right">
                  
                <Button
                        color="success"
                        type="button"
                        className="btn-icon"
                        onClick={() => this.handletoAdd()}
                        size="sm"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="ni ni-fat-add fa-lg" />
                        </span>
                        Add Invoice
                      </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col className="col-lg-4 col-md-4 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Invoice</label>
                    <Input
                      id="example-date-input"
                      type="text"
                      name="invoice"
                      placeholder="search"
                      onKeyPress={this.keyPressed}
                      value={this.state.actionFilter.invoice}
                      onChange={this.handleChangeString}
                    />
                  </FormGroup>
                </Col>
                <Col className="col-lg-4 col-md-4 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">PO</label>
                    <Input
                      id="example-date-input"
                      type="text"
                      name="po"
                      placeholder="search"
                      onKeyPress={this.keyPressed}
                      value={this.state.actionFilter.po}
                      onChange={this.handleChangeString}
                    />
                  </FormGroup>
                </Col>
                <Col className="col-lg-4 col-md-4 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Period</label>
                    <Input
                      id="example-date-input"
                      type="select"
                      onKeyPress={this.keyPressed}
                      name="period"
                      value={this.state.select_period}
                      onChange={this.handleChangePeriod}
                    >
                      {this.state.listPeriod.map((items, index) => {
                        return (
                          <option key={index} value={items.value}>
                            {items.label}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="justify-content-end">
                {this.state.show_custome ? (
                  <>
                    <Col className="col-lg-4 col-md-4 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">From date</label>
                        <Input
                          id="example-date-input"
                          type="date"
                          onKeyPress={this.keyPressed}
                          name="from_date"
                          value={this.state.actionFilter.from_date}
                          onChange={this.handleChangeString}
                          require
                          min={this.state.min_date}
                          max={this.state.max_date}
                          className={
                            this.state.paramError.from_date ? "is-invalid" : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramError.from_date}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-4 col-md-4 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">To date</label>
                        <Input
                          id="example-date-input"
                          type="date"
                          onKeyPress={this.keyPressed}
                          name="to_date"
                          value={this.state.actionFilter.to_date}
                          min={this.state.min_date}
                          max={this.state.actionFilter.to_date}
                          onChange={this.handleChangeString}
                          require
                          className={
                            this.state.paramError.to_date ? "is-invalid" : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramError.to_date}
                        </div>
                      </FormGroup>
                    </Col>
                  </>
                ) : null}
              </Row>
              <Row className="justify-content-md-center">
                <Col
                  style={{ marginTop: 20 }}
                  className="col-6 col-md-3 col-sm-6"
                  xs="12"
                >
                  <div className="text-center">
                    <Button
                      block
                      color="dark"
                      className="btn-icon"
                      type="button"
                      disabled={this.state.isResetLoading ? true : false}
                      onClick={() => this.handleReset()}
                    >
                      {this.state.isResetLoading ? (
                        <>
                          <span className="btn-inner--text">
                            <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                            Reset
                          </span>
                        </>
                      ) : (
                        <span className="btn-inner--text">Reset</span>
                      )}
                    </Button>
                  </div>
                </Col>
                <Col
                  style={{ marginTop: 20 }}
                  className="col-6 col-md-3 col-sm-6"
                  xs="12"
                >
                  <Button
                    block
                    color="primary"
                    className="btn-icon"
                    type="button"
                    disabled={this.state.isFilterLoading ? true : false}
                    onClick={() => this.handleFilter()}
                  >
                    {this.state.isFilterLoading ? (
                      <>
                        <span className="btn-inner--text">
                          <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                          Filter
                        </span>
                      </>
                    ) : (
                      <span className="btn-inner--text">Filter</span>
                    )}
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card> */}
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <ListInvoice
                  list={this.state.listInvoiceVendor}
                  listActivity={this.state.listInvoiceStatusActivity}
                  info={
                    <Col className="col-md-6 col-sm-12  ">
                      <Row>
                        <Col>
                          <InputGroup>
                            <Input
                              bsSize="sm"
                              type="search"
                              // autofocus="autofocus"
                              className="search"
                              id="search"
                              placeholder="Search"
                              result
                              onKeyPress={this.keyPressed}
                              name="invoice"
                              value={this.state.actionFilter.invoice}
                              onChange={this.handleChangeString}
                              // style={{ visibility : this.state.isFilterLoading ? 'hidden' : 'visible'}}
                            />
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm"
                              style={{
                                fontSize: "0.9em",
                                height: "31px",
                                border: "1px solid #D6D9DE",
                                borderTopLeftRadius: "0px",
                                borderBottomLeftRadius: "0px",
                              }}
                              onClick={() =>
                                this.setState(
                                  {
                                    actionFilter: {
                                      ...this.state.actionFilter,
                                      invoice: "",
                                    },
                                  },
                                  () => this.getListInvoiceVendor()
                                )
                              }
                            >
                              <i className="fa fa-times-circle text-default" />
                            </button>
                          </InputGroup>
                        </Col>
                        <Col
                          sm={{
                            //  offset: 1,
                            size: "auto",
                          }}
                          xs={{
                            size: "auto",
                          }}
                        >
                          {buttonInvoice}
                        </Col>
                      </Row>
                    </Col>
                  }
                  // actionDelivery={this.handleDelivery}
                  actionStatus={this.handleStatusDetails}
                  details={this.handleDetails}
                />
              </div>
            </div>
          </Row>
          {this.state.alert}
        </Container>
      </>
    );
  }
}
export default VendorInvoiceReguler;
