import React, { PureComponent } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Container,
  Card,
  CardHeader,
  CardBody,
  UncontrolledTooltip,
  Modal,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import StandardHeader from "components/Headers/HeaderVendor.js";
import { validateAddStandardInvoice } from "services/validate";
import SweetAlert from "react-bootstrap-sweetalert";
import config from "services/config";
import axios from "axios";
const nD = new Date();
const dt =
  nD.getFullYear() +
  "-" +
  ("0" + (nD.getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + nD.getDate()).slice(-2) +
  " " +
  ("0" + nD.getHours()).slice(-2) +
  ":" +
  ("0" + nD.getMinutes()).slice(-2) +
  ":" +
  nD.getSeconds();

const prefDate =
  new Date().getFullYear().toString().substr(-2) +
  "" +
  ("0" + (nD.getMonth() + 1)).slice(-2) +
  "" +
  ("0" + nD.getDate()).slice(-2);
class VendorUpdateRegulerInvoicev2 extends PureComponent {
  state = {
    add: 0,
    thisShow: "",
    formSubmit: false,
    showSiuk: false,
    showAddSiuk: false,
    showAddOther: false,
    listCurrencies: [],
    national:[],
    isButton: false,
    dataSiuk: {
      siuk_name: "",
      siuk_fl: "",
    },
    dateSync: {
      date: "1900-01-01 00:00:00",
      dtt: "0",
      dateDay: "0",
      h: "0",
      m: "00",
      s: "00",
    },
    display: {
      dCurr: "",
      dCurrC: "",
      dCurrCC: "",
    },
    data: {
      opt: 0,
      rv: "",
      sid: "",
      user_id: config.USER_ID,
      company_id: config.COMPANY_ID,
      purchase_order: "",
      invoice_number: "",
      tax_invoice: "",
      invoice_amount: "",
      delivery_note: "",
      bast: "",
      good_receipt: "",
      siuk: "",
      curr: "",
      curr_id: "",
    },
    files: {
      invoice_file: "",
      siuk_file: "",
    },
    filesUse: {
      useSiuk: 0,
      useInvoice: 0,
      useInvoiceDisplay: "",
      useSiukNo: "",
      useSiukFile: "",
    },
    isLoading: false,
    alert: null,
    paramError: {},
  };

  componentDidMount() {
    this.mounted = true;
    this.handleTimeServer();
    this.handleHoliday();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  
  getCurrencies = (data) => {
    axios
      .post(
        config.API_URL + "/vendor/get_currencies",
        { id: data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listCurrencies: res.data.data,
              // display: {
              //   ...this.state.display,
              //   dCurr: res.data.data[0].currency,
              //   dCurrC: res.data.data[0].code,
              //   dCurrCC: res.data.data[0].cc,
              // },
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  selectGetCurrencies = (data) => {
    axios
      .post(
        config.API_URL + "/vendor/get_currencies",
        { id: data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              display: {
                ...this.state.display,
                dCurr: res.data.data[0].currency,
                dCurrC: res.data.data[0].code,
                dCurrCC: res.data.data[0].cc,
              },
              data: {
                ...this.state.data,
                curr: res.data.data[0].code,
                curr_id: res.data.data[0].id,
              },
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getListCompany = () => {
    axios
      .post(
        config.API_URL + "/vendor/list_vendor_settings",
        { company_id: config.COMPANY_ID },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              ...this.state,
              filesUse: {
                ...this.state.filesUse,
                useSiukNo: res.data.data[0].siuk,
                useSiukFile: res.data.data[0].siuk_file,
              },
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getListData = () => {
    axios
      .post(
        config.API_URL + "/vendor/update_standard_invoice",
        { id: this.props.match.params.invoiceId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                ...this.state,
                data: {
                  ...this.state.data,
                  id: res.data.data[0].id,
                  // direct from tax 3 or 2
                  opt: res.data.data[0].opt,
                  rv: res.data.data[0].rv + 1,
                  sid: res.data.data[0].sid,
                  invoice_number: res.data.data[0].invoice,
                  purchase_order: res.data.data[0].purchase_order,
                  tax_invoice: res.data.data[0].tax_invoice,
                  invoice_amount: res.data.data[0].invoice_amount,
                  good_receipt: res.data.data[0].good_receipt,
                  delivery_note: res.data.data[0].delivery_note,
                  bast: res.data.data[0].bast,
                  siuk: res.data.data[0].siuk,
                  curr: res.data.data[0].curr,
                  ap_note: res.data.data[0].ap_note,
                  ap_pic: res.data.data[0].ap_pic,
                },
                files: {
                  ...this.state.files,
                  invoice_file: res.data.data[0].invoice_file,
                  siuk_file: res.data.data[0].siuk_file,
                },
                filesUse: {
                  ...this.state.filesUse,
                  useSiuk: res.data.data[0].siuk_file ? 1 : 0,
                  useInvoice: res.data.data[0].invoice_file ? 1 : 0,
                },
              },
              () => this.selectGetCurrencies(this.state.data.curr)
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  
  handleHoliday = () => {
    try {
      axios
        .get(
          "https://raw.githubusercontent.com/guangrei/APIHariLibur_V2/main/calendar.json",
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (this.mounted) {
            this.setState({
              national: res.data,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleTimeServer = () => {
    axios
      .post(
        config.API_URL + "/vendor/sync_server",
        { id: config.COMPANY_ID },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          this.setState(
            {
              ...this.state,
              dateSync: {
                ...this.state.dateSync,
                dateDay: res.data.data.day,
                date: res.data.data.dt,
                dtt: res.data.data.dtt,
                h: res.data.data.h,
                m: res.data.data.m,
                s: res.data.data.s,
              },
            },
            () =>this.handleTimeSync()
          );
        },
        (err) => {
          console.log(err.response);
        }
      );
  };
  handleTimeSync = () => {
    var dateISO = this.state.dateSync.date.substring(0, 10);
    var national = this.state.national;
    var found = Object.entries(national).find(
      ([key, value]) => key === dateISO && value.holiday === true
    );
    var simFound = found ? true : false;
    if (
      this.state.dateSync.dtt > 0 &&
      this.state.dateSync.dtt < 26 &&
      simFound === false
    ) {
      var dy = this.state.dateSync.dateDay;
      var dh = parseInt(this.state.dateSync.h);
      var setAStart = parseInt(9);
      var setAEnd = parseInt(15);
      if (dy > 0 && dy < 5) {
        if (dh >= setAStart && dh < setAEnd) {
          this.getListCompany();
          this.getListData();
          // this.selectGetCurrencies();
        } else {
          this.handleCancel();
        }
      } else {
        this.handleCancel();
      }
    } else {
      this.handleCancel();
    }
  };

  onChangePreview = (a) => {
    const fileUploaded = a;
    this.setState({ thisShow: fileUploaded });
  };

  postSiuk = () => {
    this.setState(
      {
        isLoading: true,
        dataSiuk: {
          ...this.state.dataSiuk,
          company_id: config.COMPANY_ID,
          datetime: dt,
          prefDate: prefDate,
        },
      },
      () => {
        let formData = new FormData();
        formData.append("datetime", this.state.dataSiuk.datetime);
        formData.append("prefDate", this.state.dataSiuk.prefDate);
        formData.append("company_id", this.state.dataSiuk.company_id);

        formData.append("siuk_name", this.state.dataSiuk.siuk_name);
        formData.append("siuk_fl", this.state.dataSiuk.siuk_fl);
        axios
          .post(
            config.API_URL + "/vendor/update_vendor_settings_siuk",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: config.TOKEN,
              },
            }
          )
          .then(
            (res) => {
              if (this.mounted) {
                this.successAlertSIUJK(res.data.s);
                // cek this
                this.setState({
                  isLoading: false,
                  data: {
                    ...this.state.data,
                    siuk: res.data.s,
                  },
                  files: {
                    ...this.state.files,
                    siuk_file: res.data.sf,
                  },
                  filesUse: {
                    ...this.state.filesUse,
                    useSiuk: res.data.sf ? 1 : 0,
                  },
                });
              }
            },
            (err) => {
              if (this.mounted) {
                if (err.response !== undefined) {
                  this.failedAlert(err.response.data.message);
                  this.setState({ isLoading: false });
                }
              }
            }
          );
      }
    );
  };

  handleSubmitInvoiceStandard = () => {
    this.setState(
      {
        isLoading: true,
        data: {
          ...this.state.data,
        },
        files: {
          ...this.state.files,
        },
        filesUse: {
          ...this.state.filesUse,
        },
      },
      () => {
        let formData = new FormData();
        formData.append("id", this.state.data.id);
        formData.append("opt", this.state.data.opt);
        formData.append("sid", this.state.data.sid);
        formData.append("version", this.state.data.rv);
        formData.append("purchase_order", this.state.data.purchase_order);
        formData.append("invoice_number", this.state.data.invoice_number);
        formData.append("invoice_amount", this.state.data.invoice_amount);
        formData.append("tax_invoice", this.state.data.tax_invoice);
        formData.append("delivery_note", this.state.data.delivery_note);
        formData.append("bast", this.state.data.bast);
        formData.append("good_receipt", this.state.data.good_receipt);
        formData.append("file_doc", this.state.filesUse.useInvoice);
        formData.append("currency", this.state.data.curr);
        formData.append("curr_id", this.state.data.curr_id);

        if (this.state.filesUse.useInvoice > 1) {
          formData.append("invoice_file", this.state.files.invoice_file);
        }

        if (this.state.filesUse.useSiuk > 0) {
          formData.append("siuk_param", this.state.filesUse.useSiuk);
          formData.append("siuk", this.state.data.siuk);
          formData.append("siuk_file", this.state.files.siuk_file);
        } else {
          formData.append("siuk_param", 0);
          formData.append("siuk", "");
          formData.append("siuk_file", "");
        }
        //   for (let i = 0; i < fileUploads.length; i++) {
        //     formData.append(`images[${i}]`, this.state.data.fileUloads[i])
        // }
        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + ", " + pair[1]);
        // }
        const paramError = validateAddStandardInvoice(
          this.state.data,
          this.state.files
        );
        this.setState({ paramError });
        if (
          Object.keys(paramError).length === 0 ||
          this.state.filesUse.useInvoice > 0
        ) {
        //   for (var pair of formData.entries()) {
        //   console.log(pair[0] + ", " + pair[1]);
        // }
          axios
            .post(config.API_URL + "/vendor/put_standard_invoice", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: config.TOKEN,
              },
            })
            .then(
              (res) => {
                if (this.mounted) {
                  this.successAlert(res.data);
                  this.setState({ isLoading: false });
                }
              },
              (err) => {
                if (this.mounted) {
                  if (err.response !== undefined) {
                    this.failedAlert(err.response.data.message);
                    this.setState({ isLoading: false });
                  }
                }
              }
            );
        } else {
          this.failedAlert("Validation Errors");
          this.setState({
            isLoading: false,
          });
        }
      }
    );
  };
  handleChangeFileInvoice = (event) => {
    var myfile = event.target.files[0].name;
    var ext = myfile.split(".").pop();
    if (ext === "pdf") {
      var parInv;
      var parInvF;
      if (event.target.name === "invoice_file") {
        parInv = this.state.filesUse.useInvoice === 0 ? 2 : 3;
        parInvF = event.target.files[0].name;
      }
      this.setState({
        files: {
          ...this.state.files,
          [event.target.name]: event.target.files[0],
        },
        filesUse: {
          ...this.state.filesUse,
          useInvoice: parInv,
          useInvoiceDisplay: parInvF,
        },
      });
    } else {
      this.setState(
        {
          ...this.state,
          files: {
            ...this.state.files,
            invoice_file: "",
          },
          filesUse: {
            ...this.state.filesUse,
            useInvoice: 0,
          },
        },
        () => this.failedAlert("The uploaded file must be in PDF format")
      );
    }
  };

  handleChangeString = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };
  handleChangeNumber = (evt) => {
    const re = /^[0-9\b]+$/;
    if (evt.target.value === "" || re.test(evt.target.value)) {
      this.setState({
        data: {
          ...this.state.data,
          [evt.target.name]: evt.target.value,
        },
      });
    }
  };
  handleCurr = (evt) => {
    var find = evt.target.value;
    var parts = find.split(",");
    this.setState({
      display: {
        ...this.state.display,
        dCurr: parts[5],
        dCurrC: parts[2],
        dCurrCC: parts[4],
      },
      data: {
        ...this.state.data,
        curr: parts[2],
        curr_id: parts[0],
      },
    });
  };
  handleCancel = () => {
    this.props.history.push(
      "/vendor/review-reguler-invoice/" +
        this.props.match.params.opt +
        "/" +
        this.props.match.params.invoiceId
    );
  };
  onChangePreview = (data) => {
    const files = config.BUCKET_URL + data;
    this.setState({
      alert: (
        <SweetAlert
          title=""
          style={{ display: "block", width: "60%" }}
          onConfirm={() => this.hideAlert()}
          confirmBtnBsStyle="secondary"
          confirmBtnText="Close"
          btnSize=""
        >
          <object width="100%" height="790" data={files} type="application/pdf">
            {" "}
          </object>
        </SweetAlert>
      ),
    });
  };
  resetSIUJK = () => {
    this.setState({
      ...this.state,
      showAddSiuk: false,
      alert: null,
      dataSiuk: {
        ...this.state.dataSiuk,
        datetime: "",
        prefDate: "",
        company_id: "",
        siuk_name: "",
        siuk_fl: "",
      },
    });
  };
  successAlertSIUJK = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.resetSIUJK()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
          // timeout={5000}
        >
          SIUJK&nbsp; <b>{`${data}`}</b>&nbsp;has been Successfully upload
        </SweetAlert>
      ),
    });
  };
  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() =>
            this.props.match.params.opt == 3
              ? this.props.history.push("/vendor/close-reguler-invoice/" + 3)
              : this.props.history.push("/vendor/close-reguler-invoice/" + 1)
          }
          // onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
          timeout={5000}
        >
          Invoice&nbsp; <b>{`${data.data}`}</b>&nbsp;has been successfully changed
        </SweetAlert>
      ),
    });
  };
  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
          timeout={8000}
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  // handleSub = (d) => {
  //   console.log(d)
  //   if (this.state.add < 0) {
  //     this.setState({
  //       add: 0,
  //     });
  //   }
  //   if (d===1){
  //       this.setState({
  //         ...this.state,
  //         add: this.state.add - 1,
  //         data:{
  //             ...this.state.data,
  //             add1:"",
  //         },
  //         files:{
  //             ...this.state.files,
  //             add_file1:""
  //         }
  //       });
  //     } else if(d===2){
  //       this.setState({
  //         ...this.state,
  //         add: this.state.add - 1,
  //         data:{
  //             ...this.state.data,
  //             add2:"",
  //         },
  //         files:{
  //             ...this.state.files,
  //             add_file2:""
  //         }
  //       });
  //     } else {
  //       this.setState({
  //         ...this.state,
  //         add: this.state.add - 1,
  //         data:{
  //             ...this.state.data,
  //             add3:"",
  //         },
  //         files:{
  //             ...this.state.files,
  //             add_file3:""
  //         }
  //       });
  //   }

  // };
  // handleAdd = () => {
  //   this.setState({
  //     add: this.state.add + 1,
  //   });
  //   if (this.state.add === 3) {
  //     this.setState({
  //       add: 3,
  //     });
  //   }
  // };
  handleClear = () => {
    this.setState({
      add: 0,
    });
  };
  handleChangeSiuk = (event) => {
    if (event.target.name === "siuk_name") {
      this.setState({
        dataSiuk: {
          ...this.state.dataSiuk,
          [event.target.name]: event.target.value,
        },
      });
    } else {
      this.setState({
        dataSiuk: {
          ...this.state.dataSiuk,
          [event.target.name]: event.target.files[0],
        },
      });
    }
  };
  handleRemove = () => {
    this.setState({
      dataSiuk: {
        ...this.state.dataSiuk,
        siuk_name: "",
        siuk_fl: "",
      },
    });
  };
  closeModal = () => {
    this.setState({
      showAddSiuk: false,
      dataSiuk: {
        ...this.state.dataSiuk,
        siuk_name: "",
        siuk_fl: "",
      },
    });
  };
  curr = (nm, ct, cd) => {
    const cur = Intl.NumberFormat(ct, {
      style: "currency",
      currency: cd,
      maximumFractionDigits: 2,
    }).format(nm);
    return cur;
  };
  render() {
    if (this.state.files.siuk_file) {
      this.setState({ ...this.state, showAddOther: true });
    }

    var links =
      "review-reguler-invoice/" +
      this.props.match.params.opt +
      "/" +
      this.props.match.params.invoiceId;
    return (
      <>
        <StandardHeader
          name="Update invoice"
          parentName="Detail invoice"
          link={links}
        />
        <Modal
          centered
          size="lg"
          isOpen={this.state.showAddSiuk}
          // toggle={this.closeModal}
        >
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0" toggle={this.hideModal}>
              <Row className="align-items-center">
                <Col xs="10">
                  <h3 className="mb-0">
                    Add Construction business license number
                    <i className="text-muted">(SIUJK)</i>
                  </h3>
                </Col>
                <Col xs="2" className="text-right">
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={() => this.closeModal()}
                  >
                    <span aria-hidden="true" style={{ fontSize: "1 rem" }}>
                      &times;
                    </span>
                  </button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <Row>
                    {this.state.dataSiuk.siuk_fl ? (
                      <>
                        <Col
                          className="col-lg-1 col-md-1 col-sm-1 text-right"
                          xs="1"
                        >
                          <div className="mx-auto mt-2 text-right">
                            <FormGroup>
                              <Button
                                id="del"
                                onClick={() => this.handleRemove()}
                                size="sm"
                                type="button"
                                style={{
                                  cursor: "pointer",
                                  objectFit: "cover",
                                }}
                              >
                                <i
                                  className="fa fa-trash text-dark"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                              <UncontrolledTooltip
                                flip
                                delay={0}
                                target={"del"}
                              >
                                Delete
                              </UncontrolledTooltip>
                            </FormGroup>
                          </div>
                        </Col>
                        <Col className="col-lg-11 col-md-11 col-sm-11" xs="11">
                          <FormGroup>
                            <Input
                              type="text"
                              onKeyPress={this.keyPressed}
                              name="siuk_name"
                              value={this.state.dataSiuk.siuk_name}
                              onChange={this.handleChangeSiuk}
                              required
                            />
                          </FormGroup>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                          <FormGroup>
                            <Input
                              type="text"
                              onKeyPress={this.keyPressed}
                              name="siuk_name"
                              value={this.state.dataSiuk.siuk_name}
                              onChange={this.handleChangeSiuk}
                              required
                            />
                          </FormGroup>
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
                {this.state.dataSiuk.siuk_name ? (
                  <>
                    <Col>
                      <FormGroup>
                        <div className="custom-file">
                          <input
                            className="custom-file-input"
                            id="customFileLang"
                            lang="en"
                            type="file"
                            name="siuk_fl"
                            // value={this.state.data.slip}
                            accept="application/pdf"
                            onChange={this.handleChangeSiuk}
                            required
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="customFileLang"
                          >
                            Select file
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                  </>
                ) : null}
              </Row>

              {this.state.dataSiuk.siuk_fl && this.state.dataSiuk.siuk_name ? (
                <>
                  <Row className="justify-content-md-center">
                    <Col
                      style={{ marginTop: 20 }}
                      className="col-6 col-md-6 col-sm-6"
                      xs="12"
                    >
                      <Button
                        block
                        color="primary"
                        className="btn-icon"
                        type="button"
                        // disabled={this.state.isFilterLoading ? true : false}
                        onClick={() => this.postSiuk()}
                      >
                        {this.state.isFilterLoading ? (
                          <>
                            <span className="btn-inner--text">
                              <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                              Upload
                            </span>
                          </>
                        ) : (
                          <span className="btn-inner--text">Upload</span>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </>
              ) : null}
            </CardBody>
          </Card>
        </Modal>
        <Container className="mt--6 mb-auto">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row>
                <Col className="text-center">
                  <h2>
                    Revision invoice
                    <span className="text-primary pull-right">
                      {this.state.data.rv ? " R" + this.state.data.rv: null}
                    </span>
                  </h2>
                </Col>
              </Row>
            </CardHeader>
          </Card>
          <Row>
            <Col className="col-lg-6 col-md-6 col-sm-12" xs="12">
              <Card>
                <CardBody>
                  <FormGroup>
                    <label className="form-control-label">
                      Purchase order number
                      <span className="text-danger">*</span>
                    </label>
                    <Input
                      type="text"
                      name="purchase_order"
                      value={this.state.data.purchase_order}
                      onChange={this.handleChangeString}
                      placeholder="Required"
                      required
                      className={
                        this.state.paramError.purchase_order ? "is-invalid" : ""
                      }
                    />
                    <div className="invalid-feedback">
                      {this.state.paramError.purchase_order}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <label className="form-control-label" id="document_invoice">
                      File document <small className="text-muted">pdf</small>
                      <UncontrolledTooltip
                        flip
                        delay={0}
                        target={"document_invoice"}
                      >
                        Please make one PDF file containing invoice file,
                        purchase order file, tax invoice file and if there is a
                        good received file, delivery note file, bast file and
                        upload it in the document invoice
                      </UncontrolledTooltip>
                      <span className="text-danger">*</span>
                    </label>
                    <Row>
                      {this.state.files.invoice_file && this.state.data.invoice_number ? (
                        <>
                          <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                           
                          <div className="mx-auto mt-2">
                              <FormGroup>
                              <Input
                        type="text"
                        name="invoice_number"
                        value={this.state.data.invoice_number}
                       disabled
                        />
                              </FormGroup>
                            </div>
                            </Col>
                          <Col className="col-lg-1 col-md-1 col-sm-1" xs="1">
                            <div className="mx-auto mt-2">
                              <FormGroup>
                                <Button
                                  onClick={() =>
                                    this.setState({
                                      ...this.state,
                                      data:{
                                        ...this.state.data,
                                        invoice_number:"",
                                      },
                                      files: {
                                        ...this.state.files,
                                        invoice_file: "",
                                      },
                                      filesUse: {
                                        ...this.state.filesUse,
                                        useInvoice: 0,
                                      },
                                    })
                                  }
                                  size="sm"
                                  type="button"
                                  style={{
                                    cursor: "pointer",
                                    objectFit: "cover",
                                  }}
                                >
                                  <i
                                    className="fa fa-trash text-dark"
                                    aria-hidden="true"
                                  ></i>
                                </Button>
                              </FormGroup>
                            </div>
                          </Col>
                          <Col
                            className="col-lg-11 col-md-11 col-sm-11"
                            xs="11"
                          >
                            {this.state.filesUse.useInvoice === 1 ? (
                              <>
                                <div className="mx-auto mt-2">
                                  <FormGroup>
                                    <Button
                                      id="pre"
                                      size="sm"
                                      type="button"
                                      style={{
                                        cursor: "pointer",
                                        objectFit: "cover",
                                      }}
                                      onClick={() =>
                                        this.onChangePreview(
                                          this.state.files.invoice_file
                                        )
                                      }
                                    >
                                      <i
                                        className="fa fa-file-pdf-o text-dark"
                                        aria-hidden="true"
                                      ></i>
                                      <span>
                                        {" " +
                                          this.state.files.invoice_file
                                            .replace("reg-doc/", "")
                                            .replace(".pdf", "")}
                                      </span>
                                    </Button>
                                  </FormGroup>
                                </div>
                              </>
                            ) : (
                              <>
                              <FormGroup>
                                <div
                                  className="mt-3"
                                  style={{ fontSize: "0.700rem" }}
                                >
                                  {" " + this.state.filesUse.useInvoiceDisplay}
                                </div>
                              </FormGroup>
                              </>
                            )}
                          </Col>
                        </>
                      ) : (
                        <>
                        <Col  className="col-lg-12 col-md-12 col-sm-12"
                            xs="12">
                              <FormGroup>
                          <Input
                            type="text"
                            name="invoice_number"
                            value={this.state.data.invoice_number}
                            onChange={this.handleChangeString}
                            required
                            placeholder="Required invoice number"
                            className={
                              this.state.paramError.invoice_number ? "is-invalid" : ""
                            }
                            />
                          <div className="invalid-feedback">
                            {this.state.paramError.invoice_number}
                          </div>
                            </FormGroup>
                        </Col>
                              {this.state.data.invoice_number ?(<>
                          <Col
                          className="col-lg-12 col-md-12 col-sm-12"
                          xs="12"
                          >
                            <FormGroup>
                            <Form>
                              <div className="custom-file">
                                <input
                                  className="custom-file-input"
                                  id="document_invoice"
                                  type="file"
                                  name="invoice_file"
                                  placeholder="Invoice number"
                                  accept="application/pdf"
                                  onChange={this.handleChangeFileInvoice}
                                  required
                                />
                                <label className="custom-file-label"></label>
                              </div>
                              {this.state.paramError.invoice_file && (
                                <small className="text-warning">
                                  {this.state.paramError.invoice_file}
                                </small>
                              )}
                            </Form>
                            </FormGroup>
                          </Col>
                              </>): null}
                        </>
                      )}
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <label className="form-control-label" for="input-curr">
                      Currency<span className="text-danger">*</span>
                    </label>
                    <InputGroup>
                      {this.state.data.curr ? (
                        <>
                          <InputGroupText>
                            <span
                              style={{
                                fontSize: "0.9em",
                                // height: "31px",
                                // border: "1px solid #D6D9DE",
                                // borderTopLeftRadius: "0px",
                                // borderBottomLeftRadius: "0px",
                              }}
                              onClick={() =>
                                this.setState({
                                  ...this.state,
                                  display: {
                                    ...this.state.display,
                                    dCurr: "",
                                    dCurrC: "",
                                    dCurrCC: "",
                                  },
                                  data: {
                                    ...this.state.data,
                                    curr: "",
                                    curr_id: "",
                                  },
                                },()=> this.getCurrencies("all"))
                              }
                            >
                              <i className="fa fa-times-circle text-default" />
                            </span>
                          </InputGroupText>
                        </>
                      ) : null}
                      <Input
                        list="curr"
                        id="input-curr"
                        onKeyPress={this.keyPressed}
                        type="search"
                        name="dCurr"
                        placeholder="Search"
                        value={this.state.display.dCurr}
                        onChange={this.handleCurr}
                      />
                      <datalist id="curr">
                        {/* <option value={"0"," "," "," "," "," "}>Select</option> */}
                        {this.state.listCurrencies.map((element) => {
                          return (
                            <option
                              value={
                                element.id +
                                "," +
                                element.symbol +
                                "," +
                                element.code +
                                "," +
                                element.country +
                                "," +
                                element.cc +
                                "," +
                                element.currency
                              }
                            >
                              {element.symbol} | {element.currency} |{" "}
                              {element.country}
                            </option>
                          );
                        })}
                      </datalist>
                      <div className="invalid-feedback">
                        {this.state.paramError.invoice_amount}
                      </div>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <label className="form-control-label">
                      Invoice amount DPP<span className="text-danger">*</span>
                      &emsp;&emsp;
                      <span style={{ float: "right" }}>
                        {this.state.data.invoice_amount && this.state.data.curr_id ? (
                          <>
                            {this.curr(
                              this.state.data.invoice_amount,
                              this.state.display.dCurrCC,
                              this.state.display.dCurrC
                            )}
                          </>
                        ) : null}
                      </span>
                    </label>
                    <InputGroup>
                      {this.state.data.curr ? (
                        <InputGroupText>
                          <b className="text-dark">
                            {this.state.display.dCurrC}
                          </b>
                        </InputGroupText>
                      ) : null}
                      <Input
                        id="inv_am"
                        type="text"
                        pattern="^[\d,]+$"
                        step="any"
                        placeholder="Required"
                        name="invoice_amount"
                        value={this.state.data.invoice_amount}
                        onChange={this.handleChangeNumber}
                        required
                        // onKeyPress={this.isNumber}
                        className={
                          this.state.paramError.invoice_amount
                            ? "is-invalid"
                            : ""
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.paramError.invoice_amount}
                      </div>
                    </InputGroup>
                  </FormGroup>
                  
                  <FormGroup>
                    <label className="form-control-label">
                      Tax invoice number
                      <span className="text-danger">*</span>
                    </label>
                    <Input
                      type="text"
                      name="tax_invoice"
                      value={this.state.data.tax_invoice}
                      onChange={this.handleChangeString}
                      required
                      placeholder="Required"
                      className={
                        this.state.paramError.tax_invoice ? "is-invalid" : ""
                      }
                    />
                    <div className="invalid-feedback">
                      {this.state.paramError.tax_invoice}
                    </div>
                  </FormGroup>{" "}
                  <FormGroup className="mb--2">
                    <p className="font-italic ">
                      {" "}
                      <span className="text-danger">*</span>{" "}
                      <p2 className="text-default">Required field</p2>
                    </p>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-lg-6 col-md-6 col-sm-12" xs="12">
            {this.state.data.ap_note ? 
            <Card className="p-2" >
                <CardBody>
                    <label className="form-control-label text-dark">Reject note</label>
                    <hr className="mb-0 mt-2"/>
                  <FormGroup className="mt-3 mb--2">
                    <h4 className="ml-4 text-dark">
                      {this.state.data.ap_note}
                    </h4>
                    </FormGroup>
                    <small className="ml-4 text-dark text-muted">
                      {this.state.data.ap_pic}
                    </small>
                    </CardBody>
                    </Card>
            : null
          } 

              <Card>
                <CardBody>
                  <FormGroup>
                    <label className="form-control-label">
                      Good receipt number
                    </label>
                    <Input
                      type="text"
                      name="good_receipt"
                      placeholder="Optional"
                      value={this.state.data.good_receipt}
                      onChange={this.handleChangeString}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <label className="form-control-label">
                      Delivery note number
                    </label>
                    <Input
                      type="text"
                      name="delivery_note"
                      placeholder="Optional"
                      value={this.state.data.delivery_note}
                      onChange={this.handleChangeString}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <label className="form-control-label">
                      Minutes of hand over number
                      <i className="text-muted">(BAST)</i>
                    </label>
                    <Input
                      type="text"
                      name="bast"
                      placeholder="Optional"
                      value={this.state.data.bast}
                      onChange={this.handleChangeString}
                      required
                    />
                  </FormGroup>
                  {this.state.filesUse.useSiuk === 1 ||
                    this.state.files.siuk_file ? (
                      <>
                        <FormGroup>
                          <label className="form-control-label">
                            Construction business license
                            <i className="text-muted">(SIUJK)</i>
                          </label>
                          <Row>
                            <Col className="col-lg-1 col-md-1 col-sm-1" xs="1">
                              <div className="mx-auto mt-2">
                                <FormGroup>
                                  <Button
                                    onClick={() =>
                                      this.setState(
                                        {
                                          ...this.state,
                                          data: {
                                            ...this.state.data,
                                            siuk: "",
                                          },
                                          files: {
                                            ...this.state.files,
                                            siuk_file: "",
                                          },
                                          filesUse: {
                                            ...this.state.filesUse,
                                            useSiuk: 2,
                                          },
                                        },
                                        () => this.getListCompany()
                                      )
                                    }
                                    size="sm"
                                    type="button"
                                    style={{
                                      cursor: "pointer",
                                      objectFit: "cover",
                                    }}
                                  >
                                    <i className="ni ni-fat-delete" />
                                  </Button>
                                </FormGroup>
                              </div>
                            </Col>
                            <Col
                              className="col-lg-11 col-md-11 col-sm-11"
                              xs="11"
                            >
                              <div className="mx-auto mt-2">
                                <FormGroup>
                                  <Button
                                    id="pre"
                                    size="sm"
                                    type="button"
                                    style={{
                                      cursor: "pointer",
                                      objectFit: "cover",
                                    }}
                                    onClick={() =>
                                      this.onChangePreview(
                                        this.state.files.siuk_file
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-file-pdf-o text-dark"
                                      aria-hidden="true"
                                    ></i>
                                    {" " + this.state.data.siuk}
                                  </Button>
                                </FormGroup>
                              </div>
                            </Col>
                          </Row>
                        </FormGroup>
                      </>
                    ) : (
                      <>
                        {this.state.filesUse.useSiukNo &&
                        this.state.filesUse.useSiukFile ? (
                          <>
                            <FormGroup>
                              <Button
                                // color="secondary"
                                size="sm"
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    showSiuk: !this.state.showSiuk,
                                    data: {
                                      ...this.state.data,
                                      siuk: this.state.filesUse.useSiukNo,
                                    },
                                    files: {
                                      ...this.state.files,
                                      siuk_file:
                                        this.state.filesUse.useSiukFile,
                                    },
                                    filesUse: {
                                      ...this.state.filesUse,
                                      useSiuk: 3,
                                    },
                                  })
                                }
                              >
                                <i className="ni ni-fat-add" />
                                Add construction business license
                                <i className="text-muted">(SIUJK)</i>
                              </Button>
                            </FormGroup>
                          </>
                        ) : (
                          <>
                            <FormGroup>
                              <a
                                className="btn btn-sm border"
                                style={{
                                  cursor: "pointer",
                                  objectFit: "cover",
                                }}
                                onClick={() =>
                                  this.setState({ showAddSiuk: true })
                                }
                              >
                                <i className="ni ni-settings-gear-65" />
                                Construction business license
                                <i className="text-muted">(SIUJK) Optional</i>
                              </a>
                              {/* </Button> */}
                            </FormGroup>
                          </>
                        )}
                      </>
                    )}
                </CardBody>
              </Card>

              {/* <Card>
                <CardBody> */}
                  {/* <hr className="mt-0 mb-3" /> */}

                    {/* <label className="form-control-label">Other document</label>
                  <FormGroup>
                    <i className="text-muted">
                      {"  "}
                      <small>Optional</small>
                    </i> */}
                    {/* {this.state.showAddOther ? (
                      <>
                        <a
                          style={{
                            cursor: "pointer",
                            objectFit: "cover",
                          }}
                          onClick={() =>
                            this.setState({
                              ...this.state,
                              showAddOther: !this.state.showAddOther,
                            })
                          }
                        >
                          <span className="pull-right">
                            <i
                              className="fa fa-chevron-circle-up"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </a>
                      </>
                    ) : (
                      <>
                        <a
                          style={{
                            cursor: "pointer",
                            objectFit: "cover",
                          }}
                          onClick={() =>
                            this.setState({
                              ...this.state,
                              showAddOther: !this.state.showAddOther,
                            })
                          }
                        >
                          <span className="pull-right">
                            <i
                              className="fa fa-chevron-circle-down"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </a>
                      </>
                  </FormGroup>
                    )} */}
                  {/*<FormGroup>
                          <Row>
                          <Col>
                          <label className="form-control-label">
                                  Additional Document 1
                                </label>
                          </Col>
                        </Row>
                        <Row>
                            <Col
                              className="mt-1 col-lg-1 col-md-1 col-sm-1"
                              xs="1"
                            >
                              <FormGroup className="mt-1">
                                <Button
                                  id="del"
                                  size="sm"
                                  type="button"
                                  style={{
                                    cursor: "pointer",
                                    objectFit: "cover",
                                  }}
                                  onClick={() => this.handleSub(2)}
                                >
                                  <i
                                    className="fa fa-trash text-dark"
                                    aria-hidden="true"
                                  ></i>
                                </Button>
                              </FormGroup>
                            </Col>
                            <Col
                              className="col-lg-11 col-md-11 col-sm-11"
                              xs="11"
                            >
                              <FormGroup>
                               
                                <Input
                                  type="text"
                                  placeholder="Additional"
                                  onChange={this.handleChangeString}
                                  value={this.state.data.add2}
                                  name="add2"
                                />
                              </FormGroup>
                            </Col>
                              <Col
                              className="col-lg-11 col-md-11 col-sm-11"
                              xs="11"
                            >
                              <Form>
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    name="add_file2"
                                    className="custom-file-input"
                                    id="customFileLang"
                                    onChange={this.handleChangeFile}
                                  />
                                  <label className="custom-file-label"></label>
                                </div>
                              </Form>
                            </Col>
                          </Row>
                          <Row>
                          <Col>
                          <label className="form-control-label">
                                  Additional Document 2
                                </label>
                          </Col>
                        </Row>
                          <Row>
                            <Col
                              className="mt-1 col-lg-1 col-md-1 col-sm-1"
                              xs="1"
                            >
                              <FormGroup className="mt-1">
                                <Button
                                  id="del"
                                  size="sm"
                                  type="button"
                                  style={{
                                    cursor: "pointer",
                                    objectFit: "cover",
                                  }}
                                  onClick={() => this.handleSub(2)}
                                >
                                  <i
                                    className="fa fa-trash text-dark"
                                    aria-hidden="true"
                                  ></i>
                                </Button>
                              </FormGroup>
                            </Col>
                            <Col
                              className="col-lg-11 col-md-11 col-sm-11"
                              xs="11"
                            >
                              <FormGroup>
                               
                                <Input
                                  type="text"
                                  placeholder="Additional"
                                  onChange={this.handleChangeString}
                                  value={this.state.data.add2}
                                  name="add2"
                                />
                              </FormGroup>
                            </Col>
                              <Col
                              className="col-lg-11 col-md-11 col-sm-11"
                              xs="11"
                            >
                              <Form>
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    name="add_file2"
                                    className="custom-file-input"
                                    id="customFileLang"
                                    onChange={this.handleChangeFile}
                                  />
                                  <label className="custom-file-label"></label>
                                </div>
                              </Form>
                            </Col>
                          </Row>
                          <Row>
                          <Col>
                          <label className="form-control-label">
                                  Additional Document 3
                                </label>
                          </Col>
                        </Row>
                          <Row>
                            <Col
                              className="mt-1 col-lg-1 col-md-1 col-sm-1"
                              xs="1"
                            >
                              <FormGroup className="mt-1">
                                <Button
                                  id="del"
                                  size="sm"
                                  type="button"
                                  style={{
                                    cursor: "pointer",
                                    objectFit: "cover",
                                  }}
                                  onClick={() => this.handleSub(3)}
                                >
                                  <i
                                    className="fa fa-trash text-dark"
                                    aria-hidden="true"
                                  ></i>
                                </Button>
                              </FormGroup>
                            </Col>
                            <Col
                              className="col-lg-11 col-md-11 col-sm-11"
                              xs="11"
                            >
                              <FormGroup>
                               
                                <Input
                                  type="text"
                                  placeholder="Additional"
                                  onChange={this.handleChangeString}
                                  value={this.state.data.add3}
                                  name="add3"
                                />
                              </FormGroup>
                            </Col>
                              <Col
                              className="col-lg-11 col-md-11 col-sm-11"
                              xs="11"
                            >
                              <Form>
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    name="add_file3"
                                    className="custom-file-input"
                                    id="customFileLang"
                                    onChange={this.handleChangeFile}
                                  />
                                  <label className="custom-file-label"></label>
                                </div>
                              </Form>
                            </Col>
                          </Row> 
                       </FormGroup>*/}
                {/* </CardBody>
              </Card> */}
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col className="col-6 col-md-3 col-sm-6" xs="12">
              <Button
                block
                outline
                color="default"
                className="btn-icon"
                type="button"
                onClick={() => this.handleCancel()}
              >
                <span className="btn-inner--text">Cancel</span>
              </Button>
            </Col>
            {this.state.files.invoice_file ? (
              <>
                <Col className="col-6 col-md-3 col-sm-6" xs="12">
                  <Button
                    block
                    color="primary"
                    className="btn-icon"
                    type="button"
                    disabled={this.state.isLoading ? true : false}
                    onClick={() => this.handleSubmitInvoiceStandard()}
                  >
                    {this.state.isLoading ? (
                      <>
                        <span className="btn-inner--text">
                          <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                          Update
                        </span>
                      </>
                    ) : (
                      <span className="btn-inner--text">Update</span>
                    )}
                  </Button>
                </Col>
              </>
            ) : null}
          </Row>
          <Row className="mt-2 mb-6">
            <Col>
              <h5>&emsp;Note</h5>
              <div className=" font-italic border border-danger">
                <ul>
                  <li>
                    <p className="mt-3">
                      Please fill in the &nbsp;
                      <a href="#inv_am" className="w3-bar-item w3-button">
                        invoice amount
                      </a>
                      &nbsp; column with the DPP invoice value only without
                      additional tax value
                    </p>
                  </li>
                  <li>
                    <p className="mt--3">
                      Please make one PDF file containing invoice file, purchase
                      order file, tax invoice file and if there is a good
                      received file, delivery note file, bast file and upload it
                      in the&nbsp;
                      <a
                        href="#document_invoice"
                        className="w3-bar-item w3-button"
                      >
                        document invoice
                      </a>
                    </p>
                  </li>
                  <li>
                    <p className="mt--3">
                      Column other document and the construction business
                      license forms are optional to be filled in if the
                      documents are outside of the notes above
                    </p>
                  </li>
                  <li>
                    <p className="mt--3">
                      Invoice input hours follow the WIB time zone
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

          {this.state.alert}
        </Container>
      </>
    );
  }
}

export default VendorUpdateRegulerInvoicev2;
