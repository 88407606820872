/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import moment from "moment";
import {
  Card,
  CardHeader,
  Alert,
  Button,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
      </label>
    </div>
  ),
});
const TaxSlipVendor = (props) => {
  const info = props.info;
  var btnPurple = {
    backgroundColor: "#8965e0",
    color: "#fff",
    border: "none",
  };
  function dateFormatter(cell, row) {
    if (!cell) {
      return "";
    }
    return (
      <>
        {moment(cell).format("DD-MM-Y")} {"  "}
      </>
    );
  }
  function dataFiles(cell, row) {
    if (!cell) {
      return "";
    }
    return (
      <>
            <div>
          <UncontrolledTooltip flip delay={0} target={"pr"}>
            Donwload File
          </UncontrolledTooltip>
              <a
                id="pr"
                style={{
                  cursor: "pointer",
                  objectFit: "cover",
                }}
                onClick={() => props.download(row)}
              >
                <i className="fa fa-download"></i>
              </a>
            </div>
      </>
    );
  }
  return (
    <>
      <Card>
        <ToolkitProvider
          data={props.list}
          keyField="id"
          columns={[
            // {
            //   text: "No",
            //   sort: "true",
            //   formatter: (cell, row, rowIndex, formatExtraData) => {
            //     return rowIndex + 1;
            //   },
            // },
            {
              dataField: "created_at",
              text: "Date",
              sort: true,
              headerAlign: "center",
              align: "center",
              formatter: dateFormatter,
            },
            // {
            //   dataField: "list",
            //   text: "files",
            //   sort: true,
            //   headerAlign: "center",
            //   align: "center",
            //   formatter: (cell,row)=>{
            //   if (cell==1){
            //     return "summary - "+row.que
            //   }
            //   return "file - "+row.que
            //   }
            // },
            {
              dataField: "files",
              text: "files name",
              sort: true,
              formatter:(cell,row)=>{
                if (row.list==1){
                  return (<>
                    <div>
              <UncontrolledTooltip flip delay={0} target={"pr"}>
                Download File
              </UncontrolledTooltip>
                  <a
                    id="pr"
                    style={{
                      cursor: "pointer",
                      objectFit: "cover",
                    }}
                    onClick={() => props.download(row)}
                  >
                    <i className="fa fa-download"></i>
                  </a>&emsp;
                    {cell}
                    </div>
                    </>)
                } else{
                return (<>
                <div>
          <UncontrolledTooltip flip delay={0} target={"pr"}>
            Download File
          </UncontrolledTooltip>
              <a
                id="pr"
                style={{
                  cursor: "pointer",
                  objectFit: "cover",
                }}
                onClick={() => props.download(row)}
              >
                <i className="fa fa-download"></i>
              </a>&emsp;
              <a
                id="dl"
                style={{
                  cursor: "pointer",
                  objectFit: "cover",
                }}
                onClick={() => props.view(row)}
              >
               <b>
                {cell}
                </b>
              </a>
                </div>
                </>)
                }
              }
            },
          ]}
        >
          {(props) => (
            <div>
              <CardHeader>
                  {info}
              </CardHeader>
              <BootstrapTable 
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                wrapperClasses="table-responsive"
                striped
                hover
                noDataIndication={() => (
                  <div style={{ height: 300 }}>
                    <Alert style={{ marginLeft: -30 }} color="secondary">
                      <strong>No data record</strong>
                    </Alert>
                  </div>
                )}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
};

export default TaxSlipVendor;
